import React, { type FunctionComponent } from 'react'
import capitalize from 'lodash/capitalize'
import { Text } from '../../text/Text'
import { type DateRangePickerProps } from '../DateRangePicker'
import {
  type WeekStartDay,
  type ComparisonPeriod,
  comparisonPeriodCopy,
} from '../utils/DateRangePickerPresets.utils'
interface DateRangePickerAdditionalDataProps
  extends Pick<DateRangePickerProps, 'showAdditionalData'> {
  comparisonPeriod?: `${ComparisonPeriod}`
  weekStartDay: WeekStartDay
}

export const DateRangePickerAdditionalData: FunctionComponent<
  React.PropsWithChildren<DateRangePickerAdditionalDataProps>
> = ({ showAdditionalData, comparisonPeriod, weekStartDay }) => {
  if (!showAdditionalData) return null

  let copy
  if (comparisonPeriod && !weekStartDay) {
    copy = `Compared to ${comparisonPeriodCopy[comparisonPeriod]}`
  }

  if (comparisonPeriod && weekStartDay) {
    copy = `Compared to ${comparisonPeriodCopy[comparisonPeriod]}, week start on ${capitalize(
      weekStartDay
    )}`
  }

  if (weekStartDay && !comparisonPeriod) {
    copy = `Week start on ${capitalize(weekStartDay)}`
  }

  return (
    <Text size="small" css={{ marginLeft: 20, textDecorationLine: 'underline' }}>
      {copy}
    </Text>
  )
}
