import React from 'react'
import { type UseFormRegister } from 'react-hook-form-7'
import { useDeepMemo } from '../../../hooks/useDeepReactHooks.hooks'

export const useExtraFields = (
  definition: Record<string, string> | undefined,
  register: UseFormRegister<any>
) => {
  const entries = useDeepMemo(() => Object.entries(definition || {}), [definition])

  return entries.map(([key, value]) => (
    <input type="hidden" key={key} value={value} {...register(key)} />
  ))
}
