import {
  type Theme,
  type StylesConfig,
  type GroupBase,
  type CSSObjectWithLabel,
} from 'react-select-5'
import { borderRadius, colors, spacing, fontWeight, fontSize } from '../../../foundation'

// Needs to be >10, which is the current `TableLoadingContainer` z-index
export const SELECT_Z_INDEX = 50
// removing 2 pixels from the height to account for 1px border on top and bottom

export const getSelectBaseStyles = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  compact,
}: {
  compact?: boolean
}): StylesConfig<Option, IsMulti, Group> => {
  const SELECT_HEIGHT = compact ? 28 : 40
  const SELECT_INDICATOR_HEIGHT = SELECT_HEIGHT - 2

  const optionPadding = compact ? 7 : spacing.X8

  return {
    multiValue: (base, state) => ({
      ...base,
      borderRadius: borderRadius.X12,
      paddingLeft: spacing.X8,
    }),

    container: (base, { isFocused }) => ({
      ...base,
      minHeight: SELECT_HEIGHT,
      width: '100%',
      fontSize: compact ? fontSize.X12 : fontSize.X15,
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      transition: 'transform 0.1s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
    }),

    indicatorSeparator: base => ({
      ...base,
      visibility: 'hidden',
      // Only will be shown when there's an additional indicator around (e.g. loading, clear, etc)
      'div ~ &': {
        visibility: 'visible',
      },
    }),

    valueContainer: (base, state) => ({
      ...base,
      gap: spacing.X4,
      gridTemplateColumns: `[icon] auto [main] 1fr`,
      '&& > *': {
        gridColumnStart: 2,
      },
    }),

    input: (base, state) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),

    placeholder: (base, { isDisabled }) => ({
      ...base,
      color: isDisabled ? colors.GRAYSCALE.X30 : colors.GRAYSCALE.X50,
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),

    control: (base, { isFocused }) => ({
      ...base,
      minHeight: SELECT_HEIGHT,
      borderRadius: borderRadius.X8,
      cursor: 'pointer',
      flex: 1,
      boxSizing: 'border-box',
      fontWeight: fontWeight.SEMIBOLD,
      outline: isFocused ? `2px solid ${colors.GRAYSCALE.X70}` : 'none',
      outlineOffset: 2,
    }),

    indicatorsContainer: (base, { isDisabled }) => ({
      ...base,
      height: SELECT_INDICATOR_HEIGHT,
      minHeight: SELECT_INDICATOR_HEIGHT,
      margin: 'auto',
      marginLeft: -8,
      svg: { fill: isDisabled ? colors.GRAYSCALE.X30 : colors.GRAYSCALE.X50 },
    }),

    menu: base => ({
      ...base,
      border: 'none',
      boxShadow: `0px 2px 8px rgba(0, 0, 0, 0.16)`,
      borderRadius: compact ? borderRadius.X4 : borderRadius.X8,
      padding: `${compact ? spacing.X4 : spacing.X8} 0`,
    }),

    menuList: base => ({
      ...base,
      padding: `0 ${compact ? spacing.X4 : spacing.X8}`,
      maxHeight: 200,
    }),

    singleValue: base => ({
      ...base,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),

    group: base => ({
      ...base,
      paddingTop: 4,
      '&:not(:first-of-type)': {
        borderTop: `1px solid ${colors.GRAYSCALE.X20}`,
      },
    }),

    groupHeading: base => ({
      ...base,
      color: colors.GRAYSCALE.X70,
      fontSize: compact ? fontSize.X12 : fontSize.X15,
      fontWeight: fontWeight.BOLD,
      padding: optionPadding,
      textTransform: 'none',

      '&:empty': {
        display: 'none',
      },
    }),

    option: (_, { isSelected, isDisabled, isFocused }) => {
      const dynamicStyles: CSSObjectWithLabel = {}

      if (isFocused) {
        dynamicStyles.backgroundColor = colors.GRAYSCALE.X10
      }

      dynamicStyles[':active'] = {
        backgroundColor: colors.GRAYSCALE.X20,
      }

      if (isDisabled) {
        dynamicStyles.color = colors.GRAYSCALE.X50
      }

      return {
        padding: optionPadding,
        height: 'auto',
        borderRadius: compact ? borderRadius.X4 : borderRadius.X8,
        fontWeight: fontWeight.SEMIBOLD,
        color: colors.GRAYSCALE.X70,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        ...dynamicStyles,
      }
    },
  }
}

export const makeSelectBaseTheme = (base: Theme): Theme => ({
  ...base,
  colors: {
    ...base.colors,
    primary: colors.GRAYSCALE.X20,
    primary75: colors.SUCCESS.DARK,
    primary50: colors.SUCCESS.REGULAR,
    primary25: colors.GRAYSCALE.X10,
    danger: colors.DETRIMENTAL.REGULAR,
    dangerLight: colors.DETRIMENTAL.LIGHT,
    neutral30: colors.GRAYSCALE.X30,
    neutral60: colors.GRAYSCALE.X50,
    neutral80: colors.GRAYSCALE.X70,
  },
})
