import React, { type FunctionComponent, useRef, useEffect } from 'react'
import { useLazyScript } from '../../../../hooks/useLazyScript.hooks'
import { useCaptchaEvent } from '../../events'
import { type ReCaptchaWindow } from './types'

export type RecaptchaCheckboxProps = {
  action: string
  sitekey: string
  onChange: (token: string) => void
  onLoad?: () => void
  ['data-testid']?: string
  className?: string
}

const RE_CAPTCHA_SCRIPT_SRC = 'https://www.google.com/recaptcha/enterprise.js'

export const RecaptchaCheckbox: FunctionComponent<
  React.PropsWithChildren<RecaptchaCheckboxProps>
> = ({ action, onChange, onLoad, sitekey, 'data-testid': dataTestId, ...props }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { isSuccess, data: grecaptcha } = useLazyScript(
    RE_CAPTCHA_SCRIPT_SRC,
    () => (window as unknown as ReCaptchaWindow).grecaptcha?.enterprise
  )

  useEffect(() => {
    if (!isSuccess || !grecaptcha) return
    if (!ref.current) return

    grecaptcha.ready(() => {
      grecaptcha.render(ref.current as HTMLElement, {
        sitekey,
        callback: onChange,
        action,
      })

      onLoad?.()
    })
  }, [action, isSuccess, onChange, sitekey, grecaptcha, onLoad])

  useCaptchaEvent('resetCaptcha', () => {
    grecaptcha?.reset()
    onChange('')
  })

  return (
    <div css={{ minHeight: 78 }} {...props}>
      <div data-testid={dataTestId} ref={ref} />
    </div>
  )
}
