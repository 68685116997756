import React, { type ReactNode, type FunctionComponent } from 'react'
import { css, type SerializedStyles } from '@emotion/react'
import { defaultLineHeight, fontSize } from '../../foundation/fontSize'
import { SectionWrapper } from './components/SectionWrapper'
import { SectionHeader } from './components/SectionHeader'

const sectionBodyStyles = css({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  fontSize: fontSize.X15,
  lineHeight: defaultLineHeight,
})

interface SectionProps {
  title: string
  subTitle?: string
  headerElement?: ReactNode
  wrapperStyles?: SerializedStyles
  bodyStyles?: SerializedStyles
  headerStyles?: SerializedStyles
}

/**
 * @deprecated use Card instead from @retailer-platform/shared-components
 */
export const Section: FunctionComponent<React.PropsWithChildren<SectionProps>> = ({
  title,
  subTitle,
  headerElement,
  children,
  wrapperStyles,
  bodyStyles,
  headerStyles,
}) => (
  <SectionWrapper css={wrapperStyles}>
    <SectionHeader title={title} subTitle={subTitle} headerStyles={headerStyles}>
      {headerElement}
    </SectionHeader>
    <div css={[sectionBodyStyles, bodyStyles]}>{children}</div>
  </SectionWrapper>
)
