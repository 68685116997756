import React, { type FunctionComponent } from 'react'
import { css } from '@emotion/react'
import { borderRadius } from '../../foundation/borderRadius'
import { colors } from '../../foundation/colors'

const sectionSeparatorStyles = css({
  display: 'block',
  width: '100%',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: colors.GRAYSCALE.X20,
  borderBottomRadius: borderRadius.X4,
})

/**
 * @deprecated use Card instead from @retailer-platform/shared-components
 */
export const SectionSeparator: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <div css={sectionSeparatorStyles} />
)
