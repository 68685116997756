import React from 'react'
import { useTabState } from 'reakit/Tab'
import { TabsContext, useTabsContext } from '../utils/context'
import { StyledTab, StyledTabList, StyledTabPanel, StyledTabsContainer } from '../utils/styles'

export const Tabs: React.FunctionComponent<
  React.PropsWithChildren<{ initialSelectedId?: string; className?: string }>
> = ({ children, initialSelectedId, ...props }) => {
  const value = useTabState({ manual: true, selectedId: initialSelectedId })

  return (
    <StyledTabsContainer {...props}>
      <TabsContext.Provider value={value}>{children}</TabsContext.Provider>
    </StyledTabsContainer>
  )
}

export const Tab: React.FunctionComponent<
  React.PropsWithChildren<{
    id: string
    disabled?: boolean
    className?: string
  }>
> = props => {
  const tabsCtx = useTabsContext()

  return <StyledTab {...tabsCtx} {...props} selected={tabsCtx.selectedId === props.id} />
}

export const TabList: React.FunctionComponent<
  React.PropsWithChildren<{ className?: string }>
> = props => {
  const tabsCtx = useTabsContext()

  return <StyledTabList {...tabsCtx} {...props} />
}

export const TabPanel: React.FunctionComponent<
  React.PropsWithChildren<{ tabId: string; className?: string }>
> = props => {
  const tabsCtx = useTabsContext()

  return <StyledTabPanel {...tabsCtx} {...props} tabId={props.tabId} />
}

// ActiveTabPanel only renders its children when the child's respective tab is active/selected
export const ActiveTabPanel: React.FunctionComponent<
  React.PropsWithChildren<{
    tabId: string
    className?: string
  }>
> = props => {
  const tabsCtx = useTabsContext()

  return (
    <StyledTabPanel {...tabsCtx} {...props} tabId={props.tabId}>
      {props.tabId === tabsCtx.selectedId ? props.children : null}
    </StyledTabPanel>
  )
}
