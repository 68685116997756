import React, { type FunctionComponent } from 'react'
import { DateIcon } from '@instacart/ids-core'
import dayjs from 'dayjs'
import { type DateRange } from '../DateRangePickerV2'
import { Text } from '../../text/Text'
import { type DateRangePickerPresetConfig } from '../utils/DateRangePickerPresets.utils'
import { PopoverTrigger } from '../../../common/popover-trigger/PopoverTrigger'
import { colors } from '../../../foundation'

export interface DateRangePickerControlProps {
  toggleDisplay: () => void
  displayOpen: boolean
  dates: DateRange
  selectedPresetConfig: DateRangePickerPresetConfig | undefined
  compact?: boolean
  className?: string
  placeholder?: string
  isDisabled?: boolean
}

const formatDisplayDate = (date: Date | null) => (date ? dayjs(date).format('MMM DD, YYYY') : '')

const renderControlText = (
  dates: DateRangePickerControlProps['dates'],
  placeholder: DateRangePickerControlProps['placeholder']
) => {
  if (dates.start || dates.end) {
    return `${formatDisplayDate(dates.start)} - ${formatDisplayDate(dates.end)}`
  } else if (placeholder) {
    return placeholder
  } else {
    return ' - '
  }
}

export const DateRangePickerControl: FunctionComponent<
  React.PropsWithChildren<DateRangePickerControlProps>
> = ({
  toggleDisplay,
  dates,
  displayOpen,
  selectedPresetConfig,
  className,
  compact = false,
  placeholder,
  isDisabled = false,
}) => (
  <PopoverTrigger
    css={{
      textWrap: 'nowrap',
      width: '100%',
      borderColor: isDisabled ? colors.GRAYSCALE.X20 : '',
      cursor: isDisabled ? 'auto' : 'pointer',
    }}
    className={className}
    disabled={isDisabled}
    onClick={toggleDisplay}
    data-testid="date-range-picker-control"
    compact={compact}
    isOpen={displayOpen}
    type="button"
  >
    <Text
      css={{
        width: 18,
        '& svg': {
          fill: colors.GRAYSCALE.X80,
        },
      }}
    >
      <DateIcon css={{ display: 'block', marginRight: 6, marginBottom: 2 }} size={18} />
    </Text>
    <Text
      weight="semibold"
      size={compact ? 'small' : 'medium'}
      css={{ color: colors.GRAYSCALE.X70 }}
    >
      {selectedPresetConfig?.key
        ? selectedPresetConfig?.label
        : renderControlText(dates, placeholder)}
    </Text>
  </PopoverTrigger>
)
