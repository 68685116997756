import React, { type FunctionComponent, useMemo } from 'react'
import styled from '@emotion/styled'
import { InformationIcon, ArrowUpIcon, ArrowDownIcon, SubtractIcon } from '@instacart/ids-core'
import isNil from 'lodash/isNil'
import { colors, defaultLineHeight, fontSize, fontWeight, spacing } from '../../foundation'
import { LoadingDots } from '../loading-dots/LoadingDots'
import { Tooltip } from '../tooltip/Tooltip'

const MetricContainer = styled.div({
  minWidth: 170,
  fontSize: fontSize.X15,
  lineHeight: defaultLineHeight,
})

const ValueContainer = styled.div(({ style }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 29,
  flexDirection: style?.flexDirection,
}))

const Value = styled.div({
  fontSize: fontSize.X31,
  fontWeight: fontWeight.BOLD,
})

const GrowthPercentageGroupContainer = styled.div({
  display: 'flex',
  paddingTop: spacing.X8,
})

const ChangeContainer = styled.div(props => ({
  marginLeft: spacing.X8,
  fontSize: fontSize.X12,
  color: props.color,
  svg: {
    fill: props.color,
  },
}))

type TitleAlignment = 'start' | 'center'

interface TitleContainerProps {
  alignment: TitleAlignment
}

const TitleContainer = styled.div<TitleContainerProps>(({ alignment = 'center' }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: fontSize.X12,
  justifyContent: alignment,
}))

const Title = styled.span({
  marginRight: spacing.X8,
  marginBottom: spacing.X8,
})

const GrowthLabel = styled.div({
  padding: `0 ${spacing.X8} 0 ${spacing.X8}`,
})

interface MetricProps {
  title?: string
  valueFormatFn: (value: number) => string
  currentPeriodValue: number | undefined | null
  valueLabel?: string
  secondaryValueLabel?: string
  defaultValue?: string
  lastPeriodValue?: number
  currentPeriodSecondaryValue?: number
  lastPeriodSecondaryValue?: number
  desiredDirection?: 'positive' | 'negative'
  suffix?: string
  tooltip?: string
  growthPercentageOrientation?: 'column' | 'row'
  'data-testid'?: string
  isLoading?: boolean
  titleAlignment?: TitleAlignment
}

export const Metric: FunctionComponent<React.PropsWithChildren<MetricProps>> = ({
  title,
  valueFormatFn,
  currentPeriodValue,
  defaultValue = '--',
  lastPeriodValue,
  currentPeriodSecondaryValue,
  lastPeriodSecondaryValue,
  desiredDirection,
  suffix,
  tooltip,
  secondaryValueLabel,
  valueLabel,
  'data-testid': testId,
  growthPercentageOrientation = 'row',
  isLoading,
  titleAlignment = 'center',
}) => {
  const {
    changePercentage,
    changeColor,
    ChangeIcon,
    SecondaryChangeIcon,
    secondaryChangePercentage,
    secondaryChangeColor,
  } = useMemo(() => {
    let changePercentage,
      secondaryChangePercentage = undefined,
      changeColor: string = colors.GRAYSCALE.X30,
      secondaryChangeColor: string = colors.GRAYSCALE.X30,
      ChangeIcon = SubtractIcon,
      SecondaryChangeIcon = SubtractIcon

    if (currentPeriodValue && lastPeriodValue) {
      changePercentage = ((currentPeriodValue - lastPeriodValue) / lastPeriodValue) * 100
      currentPeriodValue < lastPeriodValue
        ? (ChangeIcon = ArrowDownIcon)
        : (ChangeIcon = ArrowUpIcon)

      if (
        (currentPeriodValue > lastPeriodValue && desiredDirection == 'positive') ||
        (currentPeriodValue < lastPeriodValue && desiredDirection == 'negative')
      ) {
        changeColor = colors.HIGHLIGHT.REGULAR
      }

      if (
        (lastPeriodValue > currentPeriodValue && desiredDirection == 'positive') ||
        (lastPeriodValue < currentPeriodValue && desiredDirection == 'negative')
      ) {
        changeColor = colors.DETRIMENTAL.REGULAR
      }
    }
    if (currentPeriodSecondaryValue && lastPeriodSecondaryValue) {
      secondaryChangePercentage =
        ((currentPeriodSecondaryValue - lastPeriodSecondaryValue) / lastPeriodSecondaryValue) * 100

      currentPeriodSecondaryValue < lastPeriodSecondaryValue
        ? (SecondaryChangeIcon = ArrowDownIcon)
        : (SecondaryChangeIcon = ArrowUpIcon)

      if (
        (currentPeriodSecondaryValue > lastPeriodSecondaryValue &&
          desiredDirection == 'positive') ||
        (currentPeriodSecondaryValue < lastPeriodSecondaryValue && desiredDirection == 'negative')
      ) {
        secondaryChangeColor = colors.HIGHLIGHT.REGULAR
      }

      if (
        (lastPeriodSecondaryValue > currentPeriodSecondaryValue &&
          desiredDirection == 'positive') ||
        (lastPeriodSecondaryValue < currentPeriodSecondaryValue && desiredDirection == 'negative')
      ) {
        secondaryChangeColor = colors.DETRIMENTAL.REGULAR
      }
    }

    return {
      changePercentage,
      changeColor,
      secondaryChangePercentage,
      secondaryChangeColor,
      ChangeIcon,
      SecondaryChangeIcon,
    }
  }, [
    currentPeriodValue,
    lastPeriodValue,
    desiredDirection,
    currentPeriodSecondaryValue,
    lastPeriodSecondaryValue,
  ])

  const displayValue = isNil(currentPeriodValue) ? defaultValue : valueFormatFn(currentPeriodValue)

  return (
    <MetricContainer data-testid={testId}>
      <ValueContainer style={{ flexDirection: growthPercentageOrientation }}>
        {isLoading ? (
          <LoadingDots height={15} />
        ) : (
          <>
            <Value>{displayValue}</Value>

            {changePercentage !== undefined && (
              <>
                <GrowthPercentageGroupContainer>
                  <ChangeContainer color={changeColor}>
                    <ChangeIcon />
                    {changePercentage.toFixed(1) + '% '}
                    {suffix}
                  </ChangeContainer>

                  {valueLabel && <GrowthLabel>{valueLabel}</GrowthLabel>}
                  {currentPeriodSecondaryValue && secondaryChangePercentage !== undefined && (
                    <>
                      /
                      <ChangeContainer color={secondaryChangeColor}>
                        <SecondaryChangeIcon />
                        {secondaryChangePercentage.toFixed(1) + '% '}
                        {suffix}
                      </ChangeContainer>
                      {secondaryValueLabel && <GrowthLabel>{secondaryValueLabel}</GrowthLabel>}
                    </>
                  )}
                </GrowthPercentageGroupContainer>
              </>
            )}
          </>
        )}
      </ValueContainer>

      <TitleContainer alignment={titleAlignment}>
        <Title>{title}</Title>
        {tooltip && (
          <Tooltip
            placement="bottom"
            target={<InformationIcon size={16} color={'systemGrayscale30'} />}
          >
            {tooltip}
          </Tooltip>
        )}
      </TitleContainer>
    </MetricContainer>
  )
}
