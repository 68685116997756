import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { spacing } from '../../foundation'
import { useMessages } from '../../utils/intl/intl.hooks'
import logo from './assets/logo.svg'

export type PublicLayoutProps = {
  className?: string
  imageUrl?: string
  noFooter?: boolean
}

const Container = styled.div<{ imageUrl?: string }>(
  {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing.X40,
    overflow: 'scroll',
  },
  ({ theme, imageUrl }) => {
    if (imageUrl)
      return {
        background: `${theme.colors.kale} url(${imageUrl}) bottom right no-repeat`,
        backgroundSize: 415,
      }

    return { background: theme.colors.kale }
  }
)

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: spacing.X100,
  paddingBottom: spacing.X100,
  width: 590,
  margin: '0 auto',
  flex: 1,
})

const Footer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: spacing.X24,
  a: {
    ...theme.typography.bodyMedium2,
    color: theme.colors.systemGrayscale00,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const Logo = styled.img({ width: 200, position: 'absolute', top: spacing.X40, left: spacing.X40 })

export const PublicLayout: FunctionComponent<React.PropsWithChildren<PublicLayoutProps>> = ({
  children,
  className,
  imageUrl,
  noFooter,
}) => {
  const messages = useMessages({
    termsOfUse: 'sharedComponents.publicLayout.links.termsOfUse',
    privacyPolicy: 'sharedComponents.publicLayout.links.privacyPolicy',
  })

  return (
    <Container data-testid="public-layout-root" className={className} imageUrl={imageUrl}>
      <Logo src={logo} alt="Instacart" />
      <ContentWrapper>{children}</ContentWrapper>

      {!noFooter && (
        <Footer>
          <a
            href="https://docs.instacart.com/instacart/ipp-terms/"
            rel="nofollow noreferrer"
            target="_blank"
          >
            {messages.termsOfUse}
          </a>
          <a
            href="https://www.instacart.com/company/privacy"
            rel="nofollow noreferrer"
            target="_blank"
          >
            {messages.privacyPolicy}
          </a>
        </Footer>
      )}
    </Container>
  )
}
