import React, { type FunctionComponent } from 'react'
// Approved by the IPP team.
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { DatePicker, type DatePickerProps } from '@mantine/dates'
import { type DateRange } from '../DateRangePicker'

interface DateRangePickerCalendarProps {
  dates: DateRange
  onChange: (dates: [Date, Date]) => void
  excludeDate?: DatePickerProps['excludeDate']
}

export const DateRangePickerCalendar: FunctionComponent<
  React.PropsWithChildren<DateRangePickerCalendarProps>
> = ({ dates, onChange, excludeDate }) => (
  <DatePicker
    data-testid="date-range-picker-calendar"
    type="range"
    firstDayOfWeek={0}
    maxLevel="month"
    value={[dates.start, dates.end]}
    onChange={onChange}
    numberOfColumns={2}
    allowSingleDateInRange
    styles={{
      calendar: { maxWidth: '560px' },
    }}
    excludeDate={excludeDate}
    // no weekend days because we don't want them styled differently from weekdays
    weekendDays={[]}
  />
)
