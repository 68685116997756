import React, { type FunctionComponent } from 'react'
import { Text } from '../../text/Text'
import { colors } from '../../../foundation'

export const DateRangePickerLabelText: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <Text weight="semibold" size="medium" css={{ marginBottom: 8, color: colors.GRAYSCALE.X70 }}>
    {children}
  </Text>
)
