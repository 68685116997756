import styled from '@emotion/styled'
import { Tab as BaseTab, TabList as BaseTabList, TabPanel as BaseTabPanel } from 'reakit/Tab'
import { fontWeight } from '../../../foundation/fontWeight'
import { withoutProps } from '../../../hocs/withoutProps'
import { fontSize, colors, spacing } from '../../../foundation'

export const StyledTabsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

export const StyledTabList = styled(withoutProps(BaseTabList, ['defaultTabId']))({
  borderBottom: '1px solid #E0E0E0',
  paddingLeft: spacing.X20,
  display: 'flex',
})

export const StyledTab = styled(withoutProps(BaseTab, ['defaultTabId']))<{
  selected: boolean
  disabled?: boolean
}>(
  {
    background: 'transparent',
    paddingBottom: spacing.X4,
    borderBottom: '2px solid transparent',
    cursor: 'pointer',
    padding: `${spacing.X16} ${spacing.X20}`,
    textAlign: 'center',
    fontSize: fontSize.X15,
    ':hover': {
      color: 'black',
      fontWeight: fontWeight.SEMIBOLD,
      borderBottom: '2px solid black',
    },
  },
  ({ selected, disabled }) => {
    if (disabled) {
      return {
        color: colors.GRAYSCALE.X20,
        cursor: 'not-allowed',
      }
    }
    if (selected)
      return {
        color: 'black',
        fontWeight: fontWeight.SEMIBOLD,
        borderBottom: '2px solid black',
      }
  }
)

export const StyledTabPanel = styled(
  withoutProps(BaseTabPanel, ['exact', 'computedMatch', 'defaultTabId'])
)({
  flex: 1,
  ':focus-visible': {
    outline: 'none',
  },
})
