import { useQuery } from '@tanstack/react-query'
import { useRef } from 'react'

const scriptCache = new Map<string, Promise<Event>>()

const insertScript = (url: string) =>
  new Promise<Event>((resolve, reject) => {
    const script = document.createElement('script')

    script.src = url
    script.onerror = reject
    script.onload = ev => resolve(ev)

    document.body.appendChild(script)
  })

const getScriptPromise = (url: string) => {
  if (!scriptCache.has(url)) {
    scriptCache.set(url, insertScript(url))
  }

  // This ensures TS understands that the element will exist as per above
  return scriptCache.get(url) as Promise<Event>
}

export const useLazyScript = <T>(url: string, resolver: () => T) => {
  const storedResolver = useRef(resolver)

  const { data, ...rest } = useQuery<boolean>({
    staleTime: Infinity,
    queryKey: ['htmlScript', url],
    queryFn: () => getScriptPromise(url).then(ev => true),
  })

  return { ...rest, data: data ? storedResolver.current() : undefined }
}
