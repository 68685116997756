import React, { useEffect, useMemo } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  matchPath,
  generatePath,
} from 'react-router'
import { useTabState } from 'reakit/Tab'
import { useDeepMemo } from '../../../hooks/useDeepReactHooks.hooks'
import { TabsContext, useTabsContext } from '../utils/context'
import { StyledTab, StyledTabList, StyledTabPanel, StyledTabsContainer } from '../utils/styles'

/**
 * Helper component for handling not found routes,
 * which should redirect to the set defaultTabId or
 * the first one available if not provided.
 */
const NotFoundHandler = () => {
  const tabsCtx = useTabsContext()

  useEffect(
    () => {
      if (!tabsCtx.items.length) return

      if (tabsCtx.defaultTabId) {
        return tabsCtx.select(tabsCtx.defaultTabId)
      }

      return tabsCtx.select(tabsCtx.items[0].id)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabsCtx.items]
  )

  return null
}

export const RouterTabs: React.FunctionComponent<
  React.PropsWithChildren<{ defaultTabId?: string; className?: string }>
> = ({ children, defaultTabId, ...props }) => {
  const { select, ...tabs } = useTabState({ manual: true })
  const { pathname, search } = useLocation()
  const { push } = useHistory()
  const params = useParams()

  /**
   * Ideally this would use `useRouteMatch`, but since the react-router's behavior
   * is to match the path down to the current level, there won't be a way of capturing
   * a match of a child route.
   *
   * This will find the currently selected item by matching the current pathname against every
   * item defined in the tabs.
   */
  const currentTabPath = useMemo(() => {
    if (!tabs.items.length) return null

    return tabs.items.find(item =>
      matchPath(pathname, {
        path: item.id as string,
        exact: true,
      })
    )?.id
  }, [tabs.items, pathname])

  /**
   * Synchronize changes to pathname into the underlying component
   */
  useEffect(() => {
    if (currentTabPath) select(currentTabPath)
  }, [select, currentTabPath])

  const value = useDeepMemo(
    () => ({
      ...tabs,
      defaultTabId,
      select: (id: string | null) => {
        /**
         * Instead of the regular old behavior, we only focus on changing the path, the effect
         * above will take care of syncing this with the underlying component
         *
         * note that there's no null/undefined behavior, since it doesn't make much sense for
         * this scenario.
         */
        if (id) {
          const url = generatePath(id, params)
          push(url + search)
        }
      },
    }),
    [tabs, params]
  )
  return (
    <StyledTabsContainer {...props}>
      <TabsContext.Provider value={value}>{children}</TabsContext.Provider>
    </StyledTabsContainer>
  )
}

export const RouterTab: React.FunctionComponent<
  React.PropsWithChildren<{
    path: string
    disabled?: boolean
    className?: string
  }>
> = ({ path, ...props }) => {
  const tabsCtx = useTabsContext()

  return <StyledTab {...tabsCtx} {...props} selected={tabsCtx.selectedId === path} id={path} />
}

export const RouterTabList: React.FunctionComponent<
  React.PropsWithChildren<{ className?: string }>
> = props => {
  const tabsCtx = useTabsContext()

  return <StyledTabList {...tabsCtx} {...props} />
}

export const RouterTabPanels: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <Switch>
    {children}

    <NotFoundHandler />
  </Switch>
)

export const RouterTabPanel: React.FunctionComponent<
  React.PropsWithChildren<{
    path: string
    className?: string
  }>
> = props => {
  const tabsCtx = useTabsContext()

  return (
    <Route path={props.path} exact={true}>
      <StyledTabPanel {...tabsCtx} {...props} tabId={tabsCtx.selectedId as string} />
    </Route>
  )
}
