import styled from '@emotion/styled'
import { colors } from '../../../foundation/colors'
import { borderRadius } from '../../../foundation/borderRadius'

export const SectionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  background: colors.GRAYSCALE.X0,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: colors.GRAYSCALE.X20,
  borderRadius: borderRadius.X4,
})
