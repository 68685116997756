import React, { type FunctionComponent } from 'react'
import { ArrowUpIcon, ArrowDownIcon } from '@instacart/ids-core'
import styled from '@emotion/styled'
import isNumber from 'lodash/isNumber'
import { colors, fontSize, fontWeight, spacing } from '../../../foundation'
import { type MetricCardProps, type MetricCardValue } from '../MetricCard'

interface MetricCardChangeProps {
  value: MetricCardValue
  comparisonValue: MetricCardValue
  desiredDirection: MetricCardProps['desiredDirection']
  changeSuffix: MetricCardProps['changeSuffix']
}

const ChangeContainer = styled.div(props => ({
  marginLeft: spacing.X8,
  marginRight: spacing.X8,
  fontSize: fontSize.X10,
  lineHeight: '14px',
  fontWeight: fontWeight.SEMIBOLD,
  marginBottom: 6,
  color: props.color,
  display: 'flex',
  svg: {
    fill: props.color,
  },
}))

export const metricCardChangeDisplayColor = ({
  value,
  comparisonValue,
  desiredDirection,
}: {
  value: number
  comparisonValue: number
  desiredDirection: MetricCardProps['desiredDirection']
}) => {
  if (
    (value > comparisonValue && desiredDirection == 'positive') ||
    (value < comparisonValue && desiredDirection == 'negative')
  ) {
    return colors.HIGHLIGHT.REGULAR
  }

  if (
    (comparisonValue > value && desiredDirection == 'positive') ||
    (comparisonValue < value && desiredDirection == 'negative')
  ) {
    return colors.DETRIMENTAL.REGULAR
  }
}

const getDirectionIcon = (value: number, comparisonValue: number) => {
  if (value === comparisonValue) return null

  return value < comparisonValue ? (
    <ArrowDownIcon size={12} data-testid="metric-card-down-icon" />
  ) : (
    <ArrowUpIcon size={12} data-testid="metric-card-up-icon" />
  )
}

export const MetricCardChange: FunctionComponent<
  React.PropsWithChildren<MetricCardChangeProps>
> = ({ value, comparisonValue, desiredDirection, changeSuffix }) => {
  if (!isNumber(value) || !isNumber(comparisonValue)) {
    return null
  }

  const color = metricCardChangeDisplayColor({ value, comparisonValue, desiredDirection })

  const changePercentage = ((value - comparisonValue) / comparisonValue) * 100

  return (
    <ChangeContainer color={color}>
      {getDirectionIcon(value, comparisonValue)}
      {`${changePercentage.toFixed(1)}% `} {changeSuffix}
    </ChangeContainer>
  )
}
