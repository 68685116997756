import React, { type FunctionComponent } from 'react'
import { Text } from '../../text/Text'

interface DateRangePickerLabelProps {}

export const DateRangePickerLabel: FunctionComponent<
  React.PropsWithChildren<DateRangePickerLabelProps>
> = ({ children }) => (
  <Text weight="semibold" css={{ paddingRight: 8, whiteSpace: 'nowrap' }}>
    {children}
  </Text>
)
