import React, { type FunctionComponent } from 'react'
import { Button } from '../../button/Button'
import { type DateRange } from '../DateRangePicker'

interface DateRangePickerButtonsProps {
  onCancel: () => void
  onApply: () => void
  isClearable?: boolean
  onClear?: () => void
  appliedDateRange: DateRange
  autoApply?: boolean
}
export const DateRangePickerButtons: FunctionComponent<
  React.PropsWithChildren<DateRangePickerButtonsProps>
> = ({ onCancel, onApply, isClearable, onClear, appliedDateRange, autoApply }) => (
  <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
    {(appliedDateRange.start || appliedDateRange.end) && isClearable && (
      <Button
        onClick={onClear}
        css={{ width: 160 }}
        variant="tertiary"
        data-testid="date-range-picker-clear"
      >
        Clear
      </Button>
    )}

    <Button
      onClick={onCancel}
      css={{ width: 160 }}
      variant="secondary"
      data-testid="date-range-picker-cancel"
    >
      Cancel
    </Button>

    {!autoApply && (
      <Button
        css={{ width: 160, marginRight: 0 }}
        variant="primary"
        onClick={onApply}
        data-testid="date-range-picker-apply"
      >
        Apply
      </Button>
    )}
  </div>
)
