import {
  type Chart,
  type TooltipFormatterCallbackFunction,
  type TooltipFormatterContextObject,
} from 'highcharts'
import { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom'

const generateTooltipId = (chartId: number, numSeries: number) =>
  `custom-tooltip-${chartId}-${numSeries}`

interface Props {
  chart: Chart
  children(formatterContext: TooltipFormatterContextObject): JSX.Element
}

export const Tooltip = ({ chart, children }: Props) => {
  const isInit = useRef(false)
  const [context, setContext] = useState<TooltipFormatterContextObject | null>(null)

  useEffect(() => {
    const formatter: TooltipFormatterCallbackFunction = function () {
      if (!isInit.current) {
        isInit.current = true

        chart.tooltip?.refresh(this.point || this.points?.map(({ point }) => point))
        chart.tooltip?.hide(0)
      }

      setContext(this)
      return `<div id="${generateTooltipId(chart.index, chart?.series.length)}"></div>`
    }

    chart.update({
      tooltip: {
        formatter,
        useHTML: true,
      },
    })
  }, [chart])

  const node =
    chart && document.getElementById(generateTooltipId(chart.index, chart?.series.length))
  return node && context && ReactDOM.createPortal(children(context), node)
}
