import React, { type FunctionComponent } from 'react'
import { DateIcon } from '@instacart/ids-core'
import dayjs from 'dayjs'
import { type DateRange } from '../DateRangePicker'
import { type DateRangePickerPresetConfig } from '../utils/DateRangePickerPresets.utils'
import { PopoverTrigger } from '../../../common/popover-trigger/PopoverTrigger'

export interface DateRangePickerControlProps {
  toggleDisplay: () => void
  displayOpen: boolean
  dates: DateRange
  selectedPresetConfig: DateRangePickerPresetConfig | undefined
  showAdditionalData?: boolean
  compact?: boolean
  className?: string
  placeholder?: string
}

const formatDisplayDate = (date: Date | null) => (date ? dayjs(date).format('MMM DD, YYYY') : '')

const renderControlText = (
  dates: DateRangePickerControlProps['dates'],
  placeholder: DateRangePickerControlProps['placeholder']
) => {
  if (dates.start || dates.end) {
    return `(${formatDisplayDate(dates.start)} - ${formatDisplayDate(dates.end)})`
  } else if (placeholder) {
    return placeholder
  } else {
    return '( - )'
  }
}

export const DateRangePickerControl: FunctionComponent<
  React.PropsWithChildren<DateRangePickerControlProps>
> = ({
  toggleDisplay,
  dates,
  displayOpen,
  selectedPresetConfig,
  showAdditionalData,
  className,
  compact = true,
  placeholder,
}) => (
  <PopoverTrigger
    css={{ textWrap: 'nowrap', width: showAdditionalData ? '40%' : '100%' }}
    className={className}
    onClick={toggleDisplay}
    data-testid="date-range-picker-control"
    compact={compact}
    isOpen={displayOpen}
    type="button"
  >
    <div css={{ width: 16 }}>
      <DateIcon size={16} css={{ display: 'block', marginRight: 6 }} />
    </div>
    {selectedPresetConfig?.label} {renderControlText(dates, placeholder)}
  </PopoverTrigger>
)
