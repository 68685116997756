import React, { Fragment, type FunctionComponent } from 'react'
import { VictoryTooltip, type VictoryTooltipProps } from 'victory'
import { colors } from '../../foundation/colors'
import { fontSize } from '../../foundation/fontSize'

interface Props extends VictoryTooltipProps {
  chartHeight: number
}

export const LineChartTooltip: FunctionComponent<React.PropsWithChildren<Props>> = props => {
  const { x, active, chartHeight } = props

  return (
    <Fragment>
      <g visibility={active ? 'visible' : 'hidden'}>
        <line
          x1={x}
          x2={x}
          y1="0"
          // subtract 50 from the chart height to make room for x-axis labels
          y2={chartHeight - 50}
          stroke={colors.GRAYSCALE.X30}
          strokeWidth={1}
        />
      </g>
      <VictoryTooltip
        {...props}
        flyoutStyle={{
          fill: colors.GRAYSCALE.X50,
          strokeWidth: 0,
        }}
        dy={-12}
        pointerWidth={20}
        style={{ fill: colors.GRAYSCALE.X0, fontSize: fontSize.X12 }}
      />
    </Fragment>
  )
}
