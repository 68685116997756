import React, { type CSSProperties, useState } from 'react'
import {
  Carousel as MantineCarousel,
  type Embla,
  type CarouselProps as MantineCarouselProps,
} from '@mantine/carousel'
import styled from '@emotion/styled'
import { spacing } from '../../foundation/spacing'
import { colors } from '../../foundation/colors'
import { type Slide as SlideInterface } from './types'

export interface CarouselProps extends MantineCarouselProps {
  /* 
    An array of the data to show in the carousel
    Data structure looks like: 
    [
      {
        id: 'id1',
        component: <SomeReactComponent />
      },
      {
        id: 'id2',
        component: <SomeReactComponent />
      },
      ...
    ]
  */
  slides: SlideInterface[]
  /* Shows the indicators over the image */
  overlayIndicators?: boolean
  /* disables the click functionality of the indicators */
  disableIndicators?: boolean
  padding?: CSSProperties['padding']
}

interface StyledManitineCarouselProps {
  overlayIndicators: boolean
  disableIndicators: boolean
  height?: number | string
  padding?: CSSProperties['padding']
}

const StyledMantineCarousel = styled(MantineCarousel, {
  shouldForwardProp: prop =>
    prop !== 'overlayIndicators' &&
    prop !== 'height' &&
    prop !== 'disableIndicators' &&
    prop !== 'padding',
})(
  ({
    overlayIndicators,
    disableIndicators,
    height = 480,
    padding = spacing.X12,
  }: StyledManitineCarouselProps) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: height,
    padding: padding,

    '& .mantine-Carousel-indicator': {
      backgroundColor: colors.SECONDARY.REGULAR,
      width: spacing.X8,
      height: spacing.X8,
      borderRadius: '50%',
      transition: 'width 0.3s, height 0.3s',
      ...(disableIndicators ? { pointerEvents: 'none' } : {}),
    },

    '& .mantine-Carousel-control': {
      margin: spacing.X8,
    },

    '& .mantine-Carousel-viewport': {
      width: '100%',
    },

    '& .mantine-Carousel-container': {
      height: '100%',
    },

    ...(!overlayIndicators
      ? {
          '& .mantine-Carousel-indicators': {
            position: 'static',
            marginTop: spacing.X16,
          },
        }
      : {}),
  })
)

const Carousel = ({
  overlayIndicators = false,
  disableIndicators = false,
  slides = [],
  ...props
}: CarouselProps): JSX.Element => {
  const [embla, setEmbla] = useState<Embla>()

  return (
    <StyledMantineCarousel
      loop
      withIndicators
      getEmblaApi={setEmbla}
      overlayIndicators={overlayIndicators}
      disableIndicators={disableIndicators}
      {...props}
    >
      {slides.map((slide, idx) => (
        <MantineCarousel.Slide key={slide?.id || idx}>
          {typeof slide.component === 'function' ? slide.component(embla) : slide.component}
        </MantineCarousel.Slide>
      ))}
    </StyledMantineCarousel>
  )
}

export default Carousel
