import React from 'react'
import { spacing, colors } from '../../foundation'

export type ContentWidth = 720 | 960 | 1200

export type CenteredContentProps = {
  className?: string
  width?: ContentWidth
}

export const CenteredContent: React.FunctionComponent<
  React.PropsWithChildren<CenteredContentProps>
> = ({ children, width = 720, ...props }) => (
  <div css={{ backgroundColor: colors.GRAYSCALE.X10 }} {...props}>
    <div
      css={{
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingTop: spacing.X24,
        paddingBottom: spacing.X24,
        gap: spacing.X24,
        width: width,
      }}
    >
      {children}
    </div>
  </div>
)
