import React, { type PropsWithChildren } from 'react'
import {
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
  VictoryAxis,
  type VictoryChartProps,
} from 'victory'
import { INSTACART_SANS_FONT_FAMILY } from '@instacart/ids-core'
import { colors, fontSize, fontWeight } from '../../foundation'
import { Text } from '../text/Text'
import { LineChartTooltip } from './LineChartTooltip'

type AbtractData = Array<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  x: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  y: any
}>

interface Props<TData extends AbtractData> {
  data: TData
  formatXAxis?: (x: TData[0]['x']) => string
  formatYAxis?: (x: TData[0]['y']) => string
  formatTooltip?: (datum: TData[0]) => string
  width?: number
  height?: number
  title?: string
  scale?: VictoryChartProps['scale']
}

export function LineChart<TData extends AbtractData>({
  data,
  formatXAxis,
  formatYAxis,
  formatTooltip,
  width,
  height = 300,
  title,
  scale,
}: PropsWithChildren<Props<TData>>) {
  return (
    <div>
      {title && (
        <Text size="medium" weight="semibold">
          {title}
        </Text>
      )}
      <VictoryChart
        containerComponent={<VictoryVoronoiContainer voronoiDimension="x" />}
        padding={{
          top: 20,
          bottom: 50,
          right: 50,
          left: 50,
        }}
        style={{ parent: { margin: 0, padding: 0 } }}
        width={width}
        height={height}
        scale={scale}
      >
        <VictoryAxis
          tickFormat={formatXAxis}
          style={{
            axis: { stroke: colors.GRAYSCALE.X20, strokeWidth: 1 },
            tickLabels: {
              fontSize: fontSize.X12,
              fontWeight: fontWeight.REGULAR,
              fontFamily: INSTACART_SANS_FONT_FAMILY,
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={formatYAxis}
          style={{
            axis: { strokeWidth: 0 },
            axisLabel: { fontSize: 20, padding: 30 },
            grid: { stroke: colors.GRAYSCALE.X20 },
            tickLabels: {
              fontSize: fontSize.X12,
              fontWeight: fontWeight.REGULAR,
              fontFamily: INSTACART_SANS_FONT_FAMILY,
            },
          }}
        />
        <VictoryGroup
          data={data}
          labels={formatTooltip}
          labelComponent={<LineChartTooltip chartHeight={height} />}
        >
          <VictoryLine style={{ data: { stroke: colors.GRAYSCALE.X50, strokeWidth: 2 } }} />
          <VictoryScatter style={{ data: { fill: colors.GRAYSCALE.X50 } }} />
        </VictoryGroup>
      </VictoryChart>
    </div>
  )
}
