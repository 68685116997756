import React, { type FunctionComponent, useCallback, useMemo } from 'react'
import TreeMenu, {
  type MatchSearchFunction,
  type TreeMenuItem,
  type TreeMenuProps,
  type TreeNodeInArray,
} from 'react-simple-tree-menu'
import { recursiveFetchLabelsTable } from './tree.utils'
import { TreeItem } from './TreeItem'
import { TreeSearchHelper } from './TreeSearchHelper'

interface Props extends Omit<TreeMenuProps, 'disableKeyboard'> {
  highlightActive?: boolean
  displayArrowOnLeft?: boolean
  hideIcons?: boolean
  searchTerm?: string
  ItemComponent?: React.ComponentType<React.PropsWithChildren<TreeMenuItem>>
  toggleOnActivation?: boolean
}

export const Tree: FunctionComponent<React.PropsWithChildren<Props>> = ({
  highlightActive,
  displayArrowOnLeft,
  hideIcons,
  searchTerm,
  data,
  hasSearch,
  ItemComponent,
  toggleOnActivation,
  ...rest
}) => {
  const searchTable: Record<string, string> = useMemo(() => {
    const result = {}

    if (!data || !hasSearch) return result
    ;(data as TreeNodeInArray[]).forEach(item => recursiveFetchLabelsTable(item, result))

    return result
  }, [data, hasSearch])

  const matchSearch: MatchSearchFunction = useCallback(
    ({ searchTerm, key }) => searchTable[key].includes(searchTerm.toLowerCase()),
    [searchTable]
  )

  return (
    <TreeMenu {...rest} data={data} hasSearch={hasSearch} matchSearch={matchSearch} disableKeyboard>
      {({ items, search }) => (
        <React.Fragment>
          {hasSearch && <TreeSearchHelper searchFn={search} searchTerm={searchTerm} />}
          {items.map(item => (
            <TreeItem
              {...item}
              hideIcons={hideIcons}
              displayArrowOnLeft={displayArrowOnLeft}
              key={item.key}
              itemKey={item.key}
              highlightActive={highlightActive}
              toggleOnActivation={toggleOnActivation}
            >
              {ItemComponent && <ItemComponent itemKey={item.key} {...item} />}
            </TreeItem>
          ))}
        </React.Fragment>
      )}
    </TreeMenu>
  )
}
