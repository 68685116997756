import React, { useRef, type FunctionComponent, useCallback } from 'react'
import { TurnstileCaptchaCheckbox } from './components/turnstile-captcha-checkbox/TurnstileCaptchaCheckbox'
import { RecaptchaCheckbox } from './components/recaptcha-checkbox/RecaptchaCheckbox'
import { resetCaptcha, useCaptchaEvent } from './events'

export { resetCaptcha, useCaptchaEvent }

export type CaptchaProvider = 'recaptcha' | 'turnstile'

export type CaptchaCheckboxProps = {
  provider?: 'recaptcha' | 'turnstile'
  onChange: (value: string) => void
  onLoad?: () => void
  sitekey: string
  action: string
  className?: string
}

/**
 * A generic captcha interface that can be used to render captchas as long as they implement
 * the same interface.
 *
 * Helpful for making the providers easily swappable.
 */
export const CaptchaCheckbox: FunctionComponent<React.PropsWithChildren<CaptchaCheckboxProps>> = ({
  provider = 'recaptcha',
  ...props
}) => {
  const callbackRef = useRef<(token: string) => void | null>()
  callbackRef.current = props.onChange

  /**
   * this is a helper to keep the onChange callback stable, as older versions
   * react-hook-form can change on every render.
   *
   * Which can result in the component trying to call the [captchaProvider].render() method
   * multiple times, which is not great.
   *
   * If we switch to RHF 7 everywhere, this should be able to be removed.
   */
  const staticOnChange = useCallback((token: string): void => {
    callbackRef?.current?.(token)
  }, [])

  return provider === 'recaptcha' ? (
    <RecaptchaCheckbox {...props} onChange={staticOnChange} />
  ) : (
    <TurnstileCaptchaCheckbox {...props} onChange={staticOnChange} />
  )
}
