// Allowed use by IPP team.
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { DatePicker as MantineDatePicker, type CalendarProps } from '@mantine/dates'
import React, { type FunctionComponent, useMemo, useCallback, useState } from 'react'
import dayjs from 'dayjs'
import { DateIcon } from '@instacart/ids-core'
import { PopoverTrigger } from '../../common/popover-trigger/PopoverTrigger'
import { useMessages } from '../../utils/intl/intl.hooks'
import { spacing } from '../../foundation'

export type DatePickerProps = {
  'data-testid'?: string
  /**
   * Whether the date picker should be compact.
   */
  compact?: boolean
  /**
   * Additional class name to provide custom styling. Also used to enable the `css` prop.
   */
  className?: string
  /**
   * Whether the date picker is disabled.
   */
  disabled?: boolean
  /**
   * The currently selected date.
   */
  value?: Date
  onChange: (date: Date | undefined) => void
  /**
   * Props to pass to the calendar component.
   */
  calendarProps?: Omit<CalendarProps, 'value' | 'onChange'>
}

/**
 * A component for selecting a single date.
 * It uses Mantine's [Calendar](https://mantine.dev/dates/calendar/) component under the hood.
 * You can pass `calendarProps` to customize the calendar, follow the link above for more details.
 *
 * Note that not all props on the calendar are available as some are used internally and should not be overriden.
 *
 * @param param0
 * @returns
 */
export const DatePicker: FunctionComponent<React.PropsWithChildren<DatePickerProps>> = ({
  disabled,
  compact,
  onChange,
  value,
  calendarProps,
  ...props
}) => {
  const dateFormat = 'MM/DD/YYYY'
  const [isOpen, setIsOpen] = useState(false)
  const messages = useMessages({ noDateSelected: 'sharedComponents.datePicker.noDateSelected' })
  const { label, tooltip } = useMemo(
    () => ({
      tooltip: value ? dayjs(value).format(`MMM D, YYYY`) : messages.noDateSelected,
      label: value ? dayjs(value).format(dateFormat) : dateFormat.toLowerCase(),
    }),
    [value, messages]
  )

  const internalOnChange = useCallback(
    (newDate: Date | null) => {
      onChange?.(newDate || undefined)
      setIsOpen(false)
    },
    [setIsOpen, onChange]
  )

  return (
    <div css={{ position: 'relative' }}>
      <PopoverTrigger
        css={{ minWidth: compact ? 100 : 145, position: 'relative' }}
        title={tooltip}
        compact={compact}
        disabled={disabled}
        onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
        isOpen={isOpen}
        {...props}
      >
        <DateIcon size={16} css={{ display: 'block', marginRight: 6 }} />

        <span>{label}</span>
      </PopoverTrigger>

      {isOpen && (
        <MantineDatePicker
          {...calendarProps}
          value={value}
          onChange={internalOnChange}
          css={{
            position: 'absolute',
            backgroundColor: 'white',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.16)',
            borderRadius: spacing.X12,
            padding: spacing.X24,
            marginTop: 16,
            zIndex: 1,
            maxWidth: 320,
          }}
        />
      )}
    </div>
  )
}
