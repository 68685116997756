// Allowed use by IPP team.
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { MantineProvider, type MantineThemeOverride } from '@mantine/core'
import React, { type CSSProperties, type FunctionComponent } from 'react'
import { ThemeProvider as IDSThemeProvider } from '@instacart/ids-core'
import {
  INSTACART_SANS_FONT_FAMILY,
  FontLoader,
  InstacartSansFontLoaderVariants,
} from '@instacart/ids-core'
import { GlobalStyles } from '../../utils/styling/GlobalStyles'
import { colors, fontSize, fontWeight, spacing } from '../../foundation'
import { breakpoints } from '../../hooks/useGetCurrentBreakpoint.hooks'

export type ThemeProviderProps = {}
const calendarStyleOverrides = {
  calendarHeader: {
    marginBottom: spacing.X16,
    maxWidth: 'none',
    width: '100%',
  },

  calendarHeaderLevel: {
    fontWeight: fontWeight.SEMIBOLD,
    fontSize: fontSize.X15,
  },

  cell: {
    border: '1px solid #eaeaea',
  },

  monthCell: {
    border: '1px solid #eaeaea',
    height: 39,
    '&[data-with-spacing]': {
      padding: 0,
    },
  },

  month: {
    width: '100%',
  },

  yearPickerControl: {
    fontWeight: fontWeight.SEMIBOLD,
  },

  monthPickerControl: {
    fontWeight: fontWeight.SEMIBOLD,
  },

  day: {
    borderRadius: 0,
    fontWeight: fontWeight.SEMIBOLD,
    fontSize: fontSize.X12,
    width: '100%',
    height: '100%',

    '&:hover': {
      background: colors.GRAYSCALE.X50,
      color: 'white',
    },

    '&[disabled]': {
      pointerEvents: 'none' as CSSProperties['pointerEvents'],
    },

    '&[data-in-range]': {
      backgroundColor: colors.SUCCESS.LIGHT,
    },

    '&[data-in-range]:hover:not([data-selected])': {
      color: colors.GRAYSCALE.X70,
      backgroundColor: colors.GRAYSCALE.X20,
    },

    '&[data-selected]': {
      backgroundColor: colors.PRIMARY.REGULAR,
      color: 'white',
    },
  },

  weekday: {
    color: colors.GRAYSCALE.X70,
    fontSize: fontSize.X12,
  },
}

const themeOverrides: MantineThemeOverride = {
  fontFamily: `'${INSTACART_SANS_FONT_FAMILY}'`,
  colors: {
    brand: [colors.PRIMARY.REGULAR, colors.PRIMARY.DARK, colors.PRIMARY.EXTRA_DARK],
  },
  ...breakpoints,
  respectReducedMotion: false, // Mantine v6 requires this for drawer animations to work. Can be removed for Mantine v7
  primaryColor: 'brand',
  primaryShade: 0,
  components: {
    RangeCalendar: { styles: calendarStyleOverrides },
    DatePicker: { styles: calendarStyleOverrides },

    Calendar: {
      styles: calendarStyleOverrides,
      defaultProps: {
        weekendDays: [],
      },
    },

    Popover: {
      defaultProps: {
        shadow: 'md',
        withinPortal: true,
      },

      styles: {
        dropdown: {
          border: 'none',
        },
      },
    },
  },
}

export const ThemeProvider: FunctionComponent<React.PropsWithChildren<ThemeProviderProps>> = ({
  children,
}) => (
  <MantineProvider theme={themeOverrides}>
    <FontLoader
      fontFamily={INSTACART_SANS_FONT_FAMILY}
      variants={InstacartSansFontLoaderVariants}
    />
    <IDSThemeProvider>
      <GlobalStyles />
      {children}
    </IDSThemeProvider>
  </MantineProvider>
)
