import React, { type FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import capitalize from 'lodash/capitalize'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { useClickOutside } from '@mantine/hooks'
import { ConfirmIcon } from '@instacart/ids-core'
import { NewSelect } from '../new-select'
import { useMessages } from '../../utils/intl/intl.hooks'
import { Text } from '../text/Text'
import { colors, spacing } from '../../foundation'
import {
  buildDateRangePresetConfig,
  DateRangePickerPresetOption,
  findMatchingPresetConfig,
  findMatchingPresetConfigKey,
  WeekStartDay,
  weekStartDayOptions,
  buildPresetDateRangeOptions,
} from './utils/DateRangePickerPresets.utils'
import { DateRangePickerControl } from './components/DateRangePickerControl'
import { DateRangePickerDateInput } from './components/DateRangePickerDateInput'
import { DateRangePickerCalendar } from './components/DateRangePickerCalendar'
import { DateRangePickerLabel } from './components/DateRangePickerLabel'
import { DateRangePickerButtons } from './components/DateRangePickerButtons'

export interface DateRange {
  start: Date | null
  end: Date | null
  placeholder?: string
  key?: DateRangePickerPresetOption
}

interface OnChangeArg extends DateRange {
  dateRangePreset: DateRangePickerPresetOption
  weekStartDay: WeekStartDay
}
export interface DateRangePickerProps {
  onChange: (dateRange: OnChangeArg) => void
  value?: DateRange
  presets?: DateRangePickerPresetOption[]
  isDisabled?: boolean
  excludeDate?: (date: Date, tempDateRange?: DateRange) => boolean
  showWeekStartDayData?: boolean
  initialWeekStartDay?: WeekStartDay
  className?: string
  compact?: boolean
  hidePresets?: boolean
  hideWeekStartDate?: boolean
  menuCSS?: object
  autoApply?: boolean
  includeFutureDates?: boolean
  isClearable?: boolean
  placeholder?: string
}

const ButtonHeight = 28

export const DateRangePickerV2: FunctionComponent<
  React.PropsWithChildren<DateRangePickerProps>
> = ({
  value,
  onChange,
  presets,
  excludeDate,
  isDisabled = false,
  showWeekStartDayData = false,
  className,
  initialWeekStartDay = WeekStartDay.Sunday,
  compact = false,
  hidePresets,
  hideWeekStartDate,
  menuCSS,
  autoApply,
  includeFutureDates,
  isClearable,
  placeholder,
}) => {
  const messages = useMessages({
    currentPeriod: 'sharedComponents.dateRangePicker.currentPeriod',
    weekStartDay: 'sharedComponents.dateRangePicker.v2.weekStartDay',
  })

  const [internalAppliedDateRange, setInternalAppliedDateRange] = useState<DateRange>({
    start: null,
    end: null,
  })

  const [appliedWeekStartDay, setAppliedWeekStartDay] = useState<WeekStartDay>(initialWeekStartDay)

  /*
   if value prop is passed in, the component is controlled and value 
   should be used as the dateRange state
  */
  const appliedDateRange = value ? (value as DateRange) : internalAppliedDateRange

  const [tempDateRange, setTempDateRange] = useState<DateRange>({
    start: null,
    end: null,
  })

  const [tempWeekStartDay, setTempWeekStartDay] = useState<WeekStartDay>(initialWeekStartDay)

  const [displayOpen, setDisplayOpen] = useState<boolean>(false)

  const presetsConfig = useMemo(
    () => buildDateRangePresetConfig(presets, tempWeekStartDay, includeFutureDates),
    [presets, tempWeekStartDay, includeFutureDates]
  )

  const scrollToSelectedPresetItem = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ block: 'center', inline: 'start' })
    }
  }

  const presetDateRangeRefCollection = useMemo(() => {
    const refs = new Map()
    buildPresetDateRangeOptions(presetsConfig).forEach(preset => {
      refs.set(preset.value, React.createRef())
    })
    return refs
  }, [presetsConfig])

  const onApply = useCallback(() => {
    setInternalAppliedDateRange(tempDateRange)
    setAppliedWeekStartDay(tempWeekStartDay)
    onChange?.({
      ...tempDateRange,
      dateRangePreset: tempDateRange.key || DateRangePickerPresetOption.Custom,
      weekStartDay: tempWeekStartDay,
    })
  }, [onChange, tempDateRange, tempWeekStartDay])

  const onClear = useCallback(() => {
    const dateRange = { start: null, end: null }
    setInternalAppliedDateRange(dateRange)
    setAppliedWeekStartDay(tempWeekStartDay)
    onChange?.({
      ...dateRange,
      dateRangePreset: findMatchingPresetConfigKey(dateRange, presetsConfig),
      weekStartDay: tempWeekStartDay,
    })
  }, [onChange, presetsConfig, tempWeekStartDay])

  useEffect(() => {
    if (!autoApply) return

    if (
      tempDateRange.start &&
      tempDateRange.end &&
      appliedDateRange.start !== tempDateRange.start &&
      appliedDateRange.end !== tempDateRange.end
    ) {
      onApply()
      setDisplayOpen(false)
    }
  }, [
    appliedDateRange.start,
    appliedDateRange.end,
    autoApply,
    onApply,
    tempDateRange.start,
    tempDateRange.end,
  ])

  useEffect(() => {
    scrollToSelectedPresetItem(presetDateRangeRefCollection.get(appliedDateRange.key))
  }, [displayOpen, appliedDateRange.key, presetDateRangeRefCollection])

  let weekStartDayCopy
  const copy = useMessages(
    {
      weekStartsOn: 'sharedComponents.dateRangePicker.v2.weekStartsOn',
    },
    { weekStartDay: capitalize(appliedWeekStartDay) }
  )

  if (appliedWeekStartDay) {
    weekStartDayCopy = copy.weekStartsOn
  }

  const datePickerRef = useClickOutside(() => setDisplayOpen(false))

  return (
    <div
      css={{
        position: 'relative',
        opacity: isDisabled ? 0.5 : 1,
      }}
      ref={datePickerRef}
    >
      <div css={{ display: 'flex', alignItems: 'center', cursor: isDisabled ? 'auto' : 'pointer' }}>
        <DateRangePickerControl
          toggleDisplay={() => {
            // if displayOpen is toggling from false to true, reset to the temporary date range to
            // whatever the applied date range is
            if (displayOpen === false) {
              setTempDateRange(appliedDateRange)
            }

            setDisplayOpen(prevValue => !prevValue)
          }}
          isDisabled={isDisabled}
          selectedPresetConfig={presetsConfig.find(config => config.key === appliedDateRange?.key)}
          displayOpen={displayOpen}
          className={className}
          dates={appliedDateRange as DateRange}
          compact={compact}
          placeholder={placeholder}
        />
        {showWeekStartDayData && (
          <Text size="small" css={{ marginLeft: 20, whiteSpace: 'nowrap' }}>
            {weekStartDayCopy}
          </Text>
        )}
      </div>
      {displayOpen && (
        <div
          css={{
            position: 'absolute',
            top: ButtonHeight + 16,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.16)',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            // TODO: use popper to place in a portal so z-index doesn't have to be used
            zIndex: 1,
            maxWidth: 610,
            ...menuCSS,
          }}
          data-testid="date-range-picker-menu"
        >
          <div css={{ display: 'flex', height: 425 }}>
            <div
              css={
                !hidePresets
                  ? {
                      width: 186,
                      paddingRight: 32,
                      borderRight: `1px solid ${colors.GRAYSCALE.X20}`,
                      padding: '10px 10px',
                      maxHeight: 500,
                      overflowY: 'auto',
                      // Always show scrollbar
                      '::-webkit-scrollbar': {
                        WebkitAppearance: 'none',
                        width: '7px',
                      },
                      '::-webkit-scrollbar-thumb': {
                        borderRadius: '4px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        WebkitBoxShadow: '0 0 1px rgba(255, 255, 255, 0.5)',
                      },
                      msOverflowStyle: 'none', // For Internet Explorer and Edge
                      scrollbarWidth: 'auto', // For Firefox
                    }
                  : undefined
              }
            >
              {!hidePresets &&
                buildPresetDateRangeOptions(presetsConfig).map(config => {
                  const selectedPresetConfig = presetsConfig.find(
                    presetConfig => config.value === presetConfig.key
                  )
                  return (
                    <>
                      <span ref={presetDateRangeRefCollection.get(config.value)}></span>
                      <Text
                        weight="semibold"
                        key={config.value}
                        data-testid={`date-range-picker-preset-${config.value}`}
                        css={{
                          padding: '10px 0 10px 10px',
                          paddingRight: 0,
                          display: 'flex',
                          ':hover': {
                            backgroundColor: colors.GRAYSCALE.X10,
                          },
                          justifyContent: 'space-between',
                          height: 40,
                          cursor: 'pointer',
                          color: colors.GRAYSCALE.X70,
                        }}
                        onClick={() => {
                          if (selectedPresetConfig) {
                            setTempDateRange(currentDateRange => ({
                              ...currentDateRange,
                              start: selectedPresetConfig.start,
                              end: selectedPresetConfig.end,
                              key: selectedPresetConfig.key,
                            }))
                          }
                        }}
                      >
                        {config.label}
                        {tempDateRange.key === config.value && (
                          <ConfirmIcon
                            css={{
                              width: spacing.X24,
                              height: spacing.X24,
                              fill: colors.PRIMARY.DARK,
                              alignSelf: 'center',
                            }}
                          />
                        )}
                      </Text>
                    </>
                  )
                })}
            </div>
            <div css={{ padding: '20px 20px', width: 357 }}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                }}
              >
                <DateRangePickerDateInput
                  label="Start"
                  date={tempDateRange.start}
                  onChange={date => {
                    setTempDateRange(prevValue => ({ ...prevValue, start: date }))
                  }}
                />
                <span css={{ marginTop: spacing.X44, fontSize: spacing.X8 }}>__</span>
                <DateRangePickerDateInput
                  label="End"
                  date={tempDateRange.end}
                  onChange={date => {
                    setTempDateRange(prevValue => ({ ...prevValue, end: date }))
                  }}
                />
              </div>
              <div
                css={!autoApply && { display: 'flex', justifyContent: 'center', marginBottom: 32 }}
              >
                <DateRangePickerCalendar
                  dates={tempDateRange}
                  onChange={dates => {
                    const newDateRange = {
                      start: dates[0],
                      end: dates[1],
                    }
                    setTempDateRange({ ...newDateRange, key: DateRangePickerPresetOption.Custom })
                    dates[1] &&
                      scrollToSelectedPresetItem(
                        presetDateRangeRefCollection.get(DateRangePickerPresetOption.Custom)
                      )
                  }}
                  excludeDate={(date: Date) => excludeDate?.(date, tempDateRange) || false}
                />
              </div>
            </div>
          </div>
          {!autoApply && (
            <div
              css={{
                padding: '0 20px',
                display: 'flex',
                justifyContent: 'space-between',
                height: spacing.X72,
                alignItems: 'center',
                borderTop: `1px solid ${colors.GRAYSCALE.X20}`,
              }}
            >
              <div css={{ display: 'flex', alignItems: 'center' }}>
                {!hideWeekStartDate && !hidePresets && (
                  <>
                    <DateRangePickerLabel>{messages.weekStartDay}</DateRangePickerLabel>
                    <NewSelect
                      css={{ minWidth: 130 }}
                      aria-label="date-range-picker-week-start-day"
                      onChange={newWeekStartDay => {
                        /* 
                    when a new weekStartDay is selected, this component needs to recalculate the preset dates (ex: last week)
                    and update the selected dates accordingly
                  */

                        if (newWeekStartDay === undefined) return

                        setTempWeekStartDay(newWeekStartDay)

                        const updatedPresetsConfig = buildDateRangePresetConfig(
                          presets,
                          newWeekStartDay,
                          includeFutureDates
                        )

                        const currentPresetConfig = findMatchingPresetConfig(
                          tempDateRange,
                          presetsConfig
                        )
                        if (currentPresetConfig) {
                          const newPresetConfig = updatedPresetsConfig.find(
                            updatedConfig => updatedConfig.key === currentPresetConfig.key
                          )
                          if (newPresetConfig) {
                            setTempDateRange({
                              start: newPresetConfig.start,
                              end: newPresetConfig.end,
                              key: newPresetConfig.key,
                            })
                          }
                        }
                      }}
                      value={tempWeekStartDay}
                      options={weekStartDayOptions}
                    />
                  </>
                )}
              </div>
              <DateRangePickerButtons
                appliedDateRange={appliedDateRange}
                onApply={() => {
                  onApply()
                  setDisplayOpen(false)
                }}
                onCancel={() => {
                  setTempDateRange(appliedDateRange)
                  setTempWeekStartDay(appliedWeekStartDay)
                  setDisplayOpen(false)
                }}
                onClear={() => {
                  onClear()
                  setDisplayOpen(false)
                }}
                isClearable={isClearable}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
