import React, { useEffect, type FunctionComponent, useState } from 'react'
import { useLazyScript } from '../../../../hooks/useLazyScript.hooks'
import { useCaptchaEvent } from '../../events'

type TurnstileTheme = 'dark' | 'light'

export type TurnstileWindow = {
  turnstile?: {
    render: (
      element: HTMLElement,
      options: {
        sitekey: string
        callback: (token: string) => void
        action?: string
        theme: TurnstileTheme
      }
    ) => string
    reset: (id: string) => void
    remove: (id: string) => void
  }
}

export type TurnstileCaptchaCheckboxProps = {
  action?: string
  sitekey: string
  onChange: (token: string) => void
  onLoad?: () => void
  theme?: TurnstileTheme
  className?: string
}

export const TurnstileCaptchaCheckbox: FunctionComponent<
  React.PropsWithChildren<TurnstileCaptchaCheckboxProps>
> = ({ action, sitekey, onChange, theme = 'light', onLoad, ...props }) => {
  const ref = React.useRef(null)
  const [widgetId, setWidgetId] = useState<string>()
  const { isSuccess, data: turnstile } = useLazyScript(
    'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit',
    () => (window as unknown as TurnstileWindow).turnstile
  )
  useEffect(() => {
    if (!isSuccess || !turnstile) return
    if (!ref.current) return

    const id = turnstile.render(ref.current, {
      sitekey,
      callback: onChange,
      action,
      theme,
    })

    setWidgetId(id)
    onLoad?.()

    return () => turnstile.remove(id)
  }, [action, isSuccess, onChange, onLoad, sitekey, theme, turnstile])

  useCaptchaEvent('resetCaptcha', ({ action: id }) => {
    if (id !== action) return
    if (!widgetId) return
    turnstile?.reset(widgetId)
    onChange('')
  })

  return (
    <div css={{ width: 300, height: 65 }} {...props}>
      {/* Commenting for now as we want to test it in auth-service, and this would make the component dependant on ids */}
      {/* isLoading && <LoadingLockupImageRow /> */}
      <div ref={ref} />
    </div>
  )
}
