import { createEventHook } from '../../hooks/event-hooks/createEventHook'

const [emitCaptchaEvent, useCaptchaEvent] = createEventHook<{
  resetCaptcha: {
    action?: string
  }
}>()

const resetCaptcha = (action?: string) => emitCaptchaEvent('resetCaptcha', { action })

export { useCaptchaEvent, resetCaptcha }
