import React, { type ChangeEvent, forwardRef, useState } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { TextInputBase, type TextInputBaseProps } from '@instacart/ids-core'
import { borderRadius, colors } from '../../../foundation'

const HEX_COLOR_LENGTH = 7
const HEX_COLOR_REGEX = '#[a-fA-F0-9]{6}'

export interface Props extends Omit<TextInputBaseProps, 'onChange' | 'value' | 'inputLabel'> {
  label: string
  inputLabel: string
  defaultValue?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const colorInputStyles = css({
  opacity: 0,
  width: '100%',
  height: '100%',
})

const Wrapper = styled.div<{ color: string }>(
  {
    top: 16,
    width: 24,
    height: 24,
    border: `1px solid ${colors.GRAYSCALE.X20}`,
    borderRadius: borderRadius.X4,
  },
  ({ color }) => ({ backgroundColor: color })
)

const wrapperStyles = css({
  position: 'relative',
})

export const FormColorInput = forwardRef<HTMLInputElement, Props>(
  ({ id, inputLabel, label, defaultValue, onChange, ...rest }, ref) => {
    // TextInputBase does not use id or inputLabel, pull out of rest prop
    const [text, setText] = useState(defaultValue || '')
    const [color, setColor] = useState(defaultValue || '#000000')

    const regExpHex = (value: string) => RegExp(HEX_COLOR_REGEX).test(value)

    const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      setText(value)

      if (regExpHex(value)) {
        setColor(value)
      }
    }

    const onColorChange = (event: ChangeEvent<HTMLInputElement>) => {
      setText(event.target.value)
      if (regExpHex(event.target.value)) {
        setColor(event.target.value)
        onChange?.(event)
      }
    }

    return (
      <div css={wrapperStyles}>
        <TextInputBase
          {...rest}
          label={label}
          ref={ref}
          value={text}
          maxLength={HEX_COLOR_LENGTH}
          onChange={onTextChange}
          pattern={HEX_COLOR_REGEX}
          invalid={!!(text && !RegExp(HEX_COLOR_REGEX).test(text))}
          leadingIcon={
            <Wrapper color={color}>
              <input type="color" value={color} onChange={onColorChange} css={colorInputStyles} />
            </Wrapper>
          }
        />
      </div>
    )
  }
)
