import React from 'react'
import { LoadingGenericBase } from '@instacart/ids-core'

export const Loader = () => (
  <div
    css={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(255,255,255,0.7)',
    }}
  >
    <LoadingGenericBase />
  </div>
)
