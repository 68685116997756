import styled from '@emotion/styled'
import { colors, spacing } from '../../foundation'

export interface CodeProps {}

/**
 * A simple font styler component. Nice for presenting some symbol or identifier.
 */
export const Code = styled.div<CodeProps>((props: CodeProps) => ({
  display: 'inline-block',
  fontFamily: 'monospace',
  fontSize: 'inherit',
  padding: spacing.X4,
  backgroundColor: colors.GRAYSCALE.X10,
  border: `1px solid ${colors.GRAYSCALE.X30}`,
  borderRadius: spacing.X4,
  height: 'fit-content',
  textAlign: 'center',
  ...props,
}))
