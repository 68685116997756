import styled from '@emotion/styled'
import { LoadingBar } from '../../../common/loading-bar/LoadingBar'
import { borderRadius, colors, spacing } from '../../../foundation'
import { Text } from '../../../common/text/Text'

export const UploadedFileName = styled(Text)({
  alignSelf: 'start',
})

export const UploadProgressBar = styled(LoadingBar)({
  marginTop: spacing.X8,
  width: '100%',
})

export const ErrorSeparator = styled.hr({
  width: '100%',
  borderRadius: borderRadius.X4,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: colors.DETRIMENTAL.REGULAR,
})

export const ErrorMessage = styled.div({
  color: colors.DETRIMENTAL.REGULAR,
  marginBottom: spacing.X20,
  width: '100%',
  display: 'flex',
})
