import React from 'react'
import { css, type Theme } from '@emotion/react'
import 'react-table/react-table.css'
import 'react-dates/lib/css/_datepicker.css'
import icIconEot from 'ic-snacks/dist/fonts/ic-icons.eot'
import icIconWoff from 'ic-snacks/dist/fonts/ic-icons.woff'
import icIconTtf from 'ic-snacks/dist/fonts/ic-icons.ttf'
import icIconSvg from 'ic-snacks/dist/fonts/ic-icons.svg'
import { Global } from '@emotion/react'
import {
  INSTACART_SANS_FONT_FAMILY_WITH_FALLBACKS,
  type Theme as IDSTheme,
} from '@instacart/ids-core'
import { colors, spacing } from '../../foundation'

const resetStyles = css`
  /* Reset CSS */
  html,
  body,
  div,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  menu,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section {
    display: block;
  }
  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
    display: none;
  }
  body {
    line-height: 1;
  }
  menu,
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '""';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`

export const globalStyles = css`
  /* Platform CSS */

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    background: #f7f7f7;
  }

  body {
    margin: ${spacing.X0};
    box-sizing: border-box;
    font-size: 14px;
    background-color: ${colors.GRAYSCALE.X0};
  }

  input,
  button,
  submit {
    border: none;
    font-family: ${INSTACART_SANS_FONT_FAMILY_WITH_FALLBACKS};
  }

  div,
  input,
  button {
    box-sizing: border-box;
  }

  button {
    cursor: pointer;
    user-select: none;
  }

  a {
    text-decoration: none;
    color: ${colors.GRAYSCALE.X70};

    &:hover {
      color: ${colors.PRIMARY.DARK};
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  @font-face {
    font-family: 'ic-icons';
    src: url('${icIconEot}');
    src: url('${icIconEot}?#iefix') format('embedded-opentype'), url('${icIconWoff}') format('woff'),
      url('${icIconTtf}') format('truetype'), url('${icIconSvg}#ic-icons') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  //react-dates
  .CalendarMonth_table {
    margin-top: 10px;
  }

  .CalendarDay {
    vertical-align: middle;
  }

  .CalendarDay__hovered_span {
    background: #a3ea8f;
    border: 1px double #5fca44;
    color: #292929;
  }

  .CalendarDay__hovered_span:hover {
    background: #43b02a;
    border: 1px double #43b02a;
    color: #fff;
  }

  .CalendarDay__selected_span {
    background: #7fe364;
    border: 1px double #5fca44;
    color: #292929;
  }

  .CalendarDay__selected_span:hover {
    background: #5fca44;
    border: 1px double #5fca44;
    color: #fff;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #43b02a;
    border: 1px double #43b02a;
    color: #fff;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #5fca44;
    bottom: 0;
    right: 0;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #43b02a;
    bottom: 0;
    right: 0;
  }

  .SingleDatePicker_picker {
    z-index: 999;
  }

  .DateRangePickerInput,
  .SingleDatePickerInput,
  .DateInput,
  .DateInput_input {
    border-radius: 4px;
  }

  .SingleDatePickerInput__withBorder,
  .SingleDatePickerInput__withBorder_2 {
    border: none;

    .DateInput_input {
      height: 35px;
      // SNACKS COLORS GRAY_74
      border: 1px solid #bdbdbd;
      padding: 7px 2px 7px 8px;
    }

    .DateInput_input__focused {
      border: 1px solid #43b02a;
    }
  }

  // Needs to match our other form controls
  .DateRangePickerInput {
    height: 36px;
    border: 1px solid rgb(189, 189, 189);
    display: flex;
    align-items: center;
  }

  .DateRangePicker {
    .DateInput__small {
      width: 95px;
    }

    .DateInput_input__small {
      height: 34px;
      line-height: 13px;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: normal;
      color: #757575;
      padding: 10px 12px;
      border: none;
    }

    .DateInput__small:last-child .DateInput__input__small {
      padding-right: 12px;
      padding-left: 2px;
    }

    .DateRangePickerInput_arrow {
      color: #757575;
      padding-bottom: 2px;
    }
  }

  .react-sortable-container {
    pointer-events: auto !important;
    cursor: move;

    * {
      pointer-events: none;
    }
  }
`

const idsRootStyles = (theme: Theme) => ({
  body: {
    ...(theme as IDSTheme).typography.rootElement,
    // Consider removing the following line if we ever fully adopt IDS typography styles
    fontVariationSettings: '"opsz" 8',
  },
})

export const GlobalStyles = () => <Global styles={[resetStyles, idsRootStyles, globalStyles]} />
