import { useMemo } from 'react'
import { useLocation } from 'react-router'

export const useHashParams = () => {
  const location = useLocation()
  const hashFragment = location.hash

  const paramMap = useMemo(() => {
    const deHashedParams = hashFragment.substring(1) // remove the prefixed '#'
    const params = new URLSearchParams(deHashedParams)
    return [...params].reduce((o: Record<string, string>, [k, v]) => {
      // transform the string key=value `params` to an object of key:value
      o[k] = v
      return o
    }, {})
  }, [hashFragment])

  return paramMap
}
