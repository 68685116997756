import type React from 'react'
import { useEffect } from 'react'

export const TreeSearchHelper: React.FunctionComponent<
  React.PropsWithChildren<{
    searchTerm?: string
    searchFn?: (term: string) => void
  }>
> = ({ searchTerm, searchFn }) => {
  useEffect(() => {
    searchFn?.(
      // @ts-ignore, undefined means clearing out the search results
      searchTerm || undefined
    )
  }, [searchTerm, searchFn])

  return null
}
