import React from 'react'
import merge from 'deepmerge'
import PieChart from 'highcharts-react-official'
import Highcharts from 'highcharts'

interface Props {
  title?: string
  seriesName?: string
  optionProps?: Highcharts.Options
  data: { name: string; y: number; color?: string }[]
  shouldOverlayLabels?: boolean
  formatter?: (value: number) => string
  format?: string
  fontSize?: number
  showExportMenu?: boolean
}

const DEFAULT_DATA_LABEL_DISTANCE = '30%'

export const GenericPieChart = ({
  title = '',
  seriesName = '',
  optionProps = {},
  data,
  shouldOverlayLabels = false,
  formatter,
  format,
  fontSize = 11,
  showExportMenu = true,
}: Props) => {
  const options = {
    chart: {
      type: 'pie',
    },
    title: {
      text: title,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      fontSize: '300px',
    },
    series: [
      {
        name: seriesName,
        dataLabels: {
          distance: shouldOverlayLabels ? '-30%' : DEFAULT_DATA_LABEL_DISTANCE,
          ...(format && { format: format }),
          ...(formatter && {
            formatter: function (this: Highcharts.PointLabelObject) {
              if (this.y) {
                return formatter(this.y)
              }
            },
          }),
          style: {
            fontSize: fontSize,
          },
        },
        data: data,
      },
    ],
    exporting: { enabled: showExportMenu },
    ...optionProps,
  }
  const completeOptions = merge(options, optionProps)

  return (
    <>
      <div>
        <PieChart highcharts={Highcharts} options={completeOptions} />
      </div>
    </>
  )
}
