import React, { type PropsWithChildren } from 'react'
import { type CSSObject } from '@emotion/react'
import { spacing } from '../../foundation'
import { Text } from '../text/Text'

type RowContentProps = PropsWithChildren<{
  label: string
  css?: CSSObject
}>

const defaultStyles: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.X8,
}

/**
 * A content row is a way of presenting information in a label/value format.
 */
export const RowContent = ({ label, css, children }: RowContentProps) => {
  const content =
    typeof children === 'string' ? (
      <Text size="medium" weight="semibold">
        {children}
      </Text>
    ) : (
      children
    )
  return (
    <div css={{ ...defaultStyles, ...css }}>
      <Text size="small">{label}</Text>
      {content}
    </div>
  )
}
