import React, {
  Fragment,
  type FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import { NewSelect } from '../new-select'
import { useMessages } from '../../utils/intl/intl.hooks'
import {
  buildDateRangePresetConfig,
  buildPresetDateRangeOptions,
  type DateRangePickerPresetOption,
  findMatchingPresetConfig,
  findMatchingPresetConfigKey,
  WeekStartDay,
  type ComparisonPeriod,
  weekStartDayOptions,
  comparisonPeriodCopy,
} from './utils/DateRangePickerPresets.utils'
import { DateRangePickerControl } from './components/DateRangePickerControl'
import { DateRangePickerDateInput } from './components/DateRangePickerDateInput'
import { DateRangePickerCalendar } from './components/DateRangePickerCalendar'
import { DateRangePickerLabel } from './components/DateRangePickerLabel'
import { DateRangePickerButtons } from './components/DateRangePickerButtons'
import { DateRangePickerAdditionalData } from './components/DateRangePickerAdditionalData'

export interface DateRange {
  start: Date | null
  end: Date | null
  comparisonPeriod?: `${ComparisonPeriod}`
  placeholder?: string
}

interface OnChangeArg extends DateRange {
  dateRangePreset: DateRangePickerPresetOption
  weekStartDay: WeekStartDay
}
export interface DateRangePickerProps {
  onChange: (dateRange: OnChangeArg) => void
  value?: DateRange
  presets?: DateRangePickerPresetOption[]
  excludeDate?: (date: Date, tempDateRange?: DateRange) => boolean
  // dynamicExcludeDate?: (date: Date, tempDateRange: DateRange) => boolean
  showComparisonPeriod?: boolean
  showAdditionalData?: boolean
  initialWeekStartDay?: WeekStartDay
  className?: string
  compact?: boolean
  hidePresets?: boolean
  hideWeekStartDate?: boolean
  menuCSS?: object
  autoApply?: boolean
  includeFutureDates?: boolean
  isClearable?: boolean
  placeholder?: string
}

const ButtonHeight = 28

export const DateRangePicker: FunctionComponent<React.PropsWithChildren<DateRangePickerProps>> = ({
  value,
  onChange,
  presets,
  excludeDate,
  showComparisonPeriod,
  showAdditionalData,
  className,
  initialWeekStartDay = WeekStartDay.Sunday,
  compact = true,
  hidePresets,
  hideWeekStartDate,
  menuCSS,
  autoApply,
  includeFutureDates,
  isClearable,
  placeholder,
  // dynamicExcludeDate,
}) => {
  const messages = useMessages({
    currentPeriod: 'sharedComponents.dateRangePicker.currentPeriod',
    comparisonPeriod: 'sharedComponents.dateRangePicker.comparisonPeriod',
    weekStartDay: 'sharedComponents.dateRangePicker.weekStartDay',
  })

  const [internalAppliedDateRange, setInternalAppliedDateRange] = useState<DateRange>({
    start: null,
    end: null,
  })

  const [appliedWeekStartDay, setAppliedWeekStartDay] = useState<WeekStartDay>(initialWeekStartDay)

  const datePickerRef = useRef<HTMLDivElement>(null)

  /*
   if value prop is passed in, the component is controlled and value
   should be used as the dateRange state
  */
  const appliedDateRange = value ? (value as DateRange) : internalAppliedDateRange

  const [tempDateRange, setTempDateRange] = useState<DateRange>({
    start: null,
    end: null,
  })

  const [tempWeekStartDay, setTempWeekStartDay] = useState<WeekStartDay>(initialWeekStartDay)

  const [displayOpen, setDisplayOpen] = useState<boolean>(false)

  const presetsConfig = useMemo(
    () => buildDateRangePresetConfig(presets, tempWeekStartDay, includeFutureDates),
    [presets, tempWeekStartDay, includeFutureDates]
  )

  const onApply = useCallback(() => {
    setInternalAppliedDateRange(tempDateRange)
    setAppliedWeekStartDay(tempWeekStartDay)
    onChange?.({
      ...tempDateRange,
      dateRangePreset: findMatchingPresetConfigKey(tempDateRange, presetsConfig),
      weekStartDay: tempWeekStartDay,
    })
  }, [onChange, presetsConfig, tempDateRange, tempWeekStartDay])

  const onClear = useCallback(() => {
    const dateRange = { start: null, end: null }
    setInternalAppliedDateRange(dateRange)
    setAppliedWeekStartDay(tempWeekStartDay)
    onChange?.({
      ...dateRange,
      dateRangePreset: findMatchingPresetConfigKey(dateRange, presetsConfig),
      weekStartDay: tempWeekStartDay,
    })
  }, [onChange, presetsConfig, tempWeekStartDay])

  useEffect(() => {
    if (!autoApply) return

    if (
      tempDateRange.start &&
      tempDateRange.end &&
      appliedDateRange.start !== tempDateRange.start &&
      appliedDateRange.end !== tempDateRange.end
    ) {
      onApply()
      setDisplayOpen(false)
    }
  }, [
    appliedDateRange.start,
    appliedDateRange.end,
    autoApply,
    onApply,
    tempDateRange.start,
    tempDateRange.end,
  ])

  const handleClickOutside = useCallback((event: MouseEvent) => {
    // close the menu when the user clicks outside of the dropdown menu
    if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
      setDisplayOpen(false)
    }
  }, [])

  useEffect(() => {
    if (displayOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [displayOpen, handleClickOutside])

  return (
    <div css={{ position: 'relative' }} ref={datePickerRef}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <DateRangePickerControl
          toggleDisplay={() => {
            // if displayOpen is toggling from false to true, reset to the temporary date range to
            // whatever the applied date range is
            if (displayOpen === false) {
              setTempDateRange(appliedDateRange)
            }

            setDisplayOpen(prevValue => !prevValue)
          }}
          selectedPresetConfig={findMatchingPresetConfig(appliedDateRange, presetsConfig)}
          displayOpen={displayOpen}
          className={className}
          dates={appliedDateRange as DateRange}
          showAdditionalData={showAdditionalData}
          compact={compact}
          placeholder={placeholder}
        />
        <DateRangePickerAdditionalData
          comparisonPeriod={appliedDateRange.comparisonPeriod}
          weekStartDay={appliedWeekStartDay}
          showAdditionalData={showAdditionalData}
        />
      </div>
      {displayOpen && (
        <div
          css={{
            position: 'absolute',
            top: ButtonHeight + 16,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.16)',
            borderRadius: '12px',
            padding: '30px 20px',
            display: 'flex',
            backgroundColor: 'white',
            // TODO: use popper to place in a portal so z-index doesn't have to be used
            zIndex: 1,
            ...menuCSS,
          }}
          data-testid="date-range-picker-menu"
        >
          <div
            css={
              !hidePresets || showComparisonPeriod || !hideWeekStartDate
                ? { width: 280, paddingRight: 32 }
                : undefined
            }
          >
            {!hidePresets && (
              <>
                <DateRangePickerLabel>{messages.currentPeriod}</DateRangePickerLabel>
                <NewSelect
                  compact
                  onChange={newPresetKey => {
                    const selectedPresetConfig = presetsConfig.find(
                      config => config.key === newPresetKey
                    )

                    if (selectedPresetConfig) {
                      setTempDateRange(currentDateRange => ({
                        ...currentDateRange,
                        start: selectedPresetConfig.start,
                        end: selectedPresetConfig.end,
                      }))
                    }
                  }}
                  value={findMatchingPresetConfigKey(tempDateRange, presetsConfig)}
                  options={buildPresetDateRangeOptions(presetsConfig)}
                  css={{ marginBottom: 14 }}
                  aria-label="date-range-picker-preset"
                />
              </>
            )}
            {showComparisonPeriod && (
              <Fragment>
                <DateRangePickerLabel>{messages.comparisonPeriod}</DateRangePickerLabel>
                <NewSelect
                  compact
                  onChange={newComparisonPeriod => {
                    if (newComparisonPeriod === undefined) return

                    setTempDateRange(prevValue => ({
                      ...prevValue,
                      comparisonPeriod: newComparisonPeriod,
                    }))
                  }}
                  value={tempDateRange.comparisonPeriod}
                  options={
                    [
                      {
                        label: comparisonPeriodCopy.lastPeriod,
                        value: 'lastPeriod',
                      },
                      {
                        label: comparisonPeriodCopy.lastYear,
                        value: 'lastYear',
                      },
                      {
                        label: comparisonPeriodCopy.lastPeriodDayOfWeekMatch,
                        value: 'lastPeriodDayOfWeekMatch',
                      },
                      {
                        label: comparisonPeriodCopy.lastYearDayOfWeekMatch,
                        value: 'lastYearDayOfWeekMatch',
                      },
                    ] as const
                  }
                  css={{ marginBottom: 14 }}
                  aria-label="date-range-picker-comparison-period"
                />
              </Fragment>
            )}

            {!hideWeekStartDate && (
              <>
                <DateRangePickerLabel>{messages.weekStartDay}</DateRangePickerLabel>
                <NewSelect
                  compact
                  aria-label="date-range-picker-week-start-day"
                  onChange={newWeekStartDay => {
                    /*
                    when a new weekStartDay is selected, this component needs to recalculate the preset dates (ex: last week)
                    and update the selected dates accordingly
                  */

                    if (newWeekStartDay === undefined) return

                    setTempWeekStartDay(newWeekStartDay)

                    const updatedPresetsConfig = buildDateRangePresetConfig(
                      presets,
                      newWeekStartDay,
                      includeFutureDates
                    )

                    const currentPresetConfig = findMatchingPresetConfig(
                      tempDateRange,
                      presetsConfig
                    )

                    if (currentPresetConfig) {
                      const newPresetConfig = updatedPresetsConfig.find(
                        updatedConfig => updatedConfig.key === currentPresetConfig.key
                      )

                      if (newPresetConfig) {
                        setTempDateRange({
                          start: newPresetConfig.start,
                          end: newPresetConfig.end,
                        })
                      }
                    }
                  }}
                  value={tempWeekStartDay}
                  options={weekStartDayOptions}
                />
              </>
            )}
          </div>
          <div>
            <div css={{ display: 'flex', justifyContent: 'space-between', marginBottom: 32 }}>
              <DateRangePickerDateInput
                label="Start Date"
                date={tempDateRange.start}
                onChange={date => {
                  setTempDateRange(prevValue => ({ ...prevValue, start: date }))
                }}
              />

              <DateRangePickerDateInput
                label="End Date"
                date={tempDateRange.end}
                onChange={date => {
                  setTempDateRange(prevValue => ({ ...prevValue, end: date }))
                }}
              />
            </div>
            <div css={!autoApply && { marginBottom: 32 }}>
              <DateRangePickerCalendar
                dates={tempDateRange}
                onChange={dates => {
                  setTempDateRange(prevValue => ({
                    ...prevValue,
                    start: dates[0],
                    end: dates[1],
                  }))
                }}
                excludeDate={(date: Date) => excludeDate?.(date, tempDateRange) || false}
              />
            </div>
            <DateRangePickerButtons
              autoApply={autoApply}
              appliedDateRange={appliedDateRange}
              onApply={() => {
                onApply()
                setDisplayOpen(false)
              }}
              onCancel={() => {
                setTempDateRange(appliedDateRange)
                setTempWeekStartDay(appliedWeekStartDay)
                setDisplayOpen(false)
              }}
              onClear={() => {
                onClear()
                setDisplayOpen(false)
              }}
              isClearable={isClearable}
            />
          </div>
        </div>
      )}
    </div>
  )
}
