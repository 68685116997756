import styled from '@emotion/styled'
import { colors } from '../../foundation'

interface PageLayoutProps {
  noHeader?: boolean
  noSidebar?: boolean
  noFooter?: boolean
}

export const PageLayout = styled.div<PageLayoutProps>(
  {
    display: 'grid',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  ({ noHeader, noSidebar, noFooter }) => {
    const gridTemplateRows = ['minmax(min-content, 48px)', '1fr', 'minmax(min-content, 48px)']
    const gridTemplateColumns = ['250px', '1fr']
    const gridTemplateAreas = ['header header', 'sidebar content', 'footer footer']

    if (noSidebar) {
      gridTemplateAreas[1] = 'content content'
      gridTemplateColumns.shift()
    }

    if (noHeader) {
      gridTemplateRows.shift()
      gridTemplateAreas.shift()
    }

    if (noFooter) {
      gridTemplateRows.pop()
      gridTemplateAreas.pop()
    }

    return {
      gridTemplateRows: gridTemplateRows.join(' '),
      gridTemplateColumns: gridTemplateColumns.join(' '),
      gridTemplateAreas: gridTemplateAreas.map(v => `"${v}"`).join(' '),
    }
  }
)

export const PageLayoutHeader = styled.div({
  boxSizing: 'border-box',
  gridArea: 'header',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: `1px solid ${colors.GRAYSCALE.X20}`,
})

export const PageLayoutSidebar = styled.div({
  boxSizing: 'border-box',
  gridArea: 'sidebar',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  overflowY: 'scroll',
})

export const PageLayoutContent = styled.div({
  gridArea: 'content',
  boxSizing: 'border-box',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  overflowY: 'scroll',
})

export const PageLayoutFooter = styled.div({
  boxSizing: 'border-box',
  gridArea: 'footer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderTop: `1px solid ${colors.GRAYSCALE.X20}`,
})
