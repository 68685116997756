import { useEffect, useCallback } from 'react'
import { type UseDragAndDropProps } from './fileUploader.types'

export const useDragAndDrop = ({
  dropAreaRef,
  onDragStart,
  onDragEnd,
  onDrop,
}: UseDragAndDropProps) => {
  const preventDefaults = useCallback((e: Event) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  useEffect(() => {
    const dropArea = dropAreaRef.current
    const dragEvents = ['dragenter', 'dragover', 'dragleave', 'drop']
    const highlightEvents = ['dragenter', 'dragover']
    const unHighlightEvents = ['dragleave', 'drop']

    if (dropArea) {
      dragEvents.forEach(event => {
        dropArea.addEventListener(event, preventDefaults, false)
      })
      highlightEvents.forEach(event => {
        dropArea.addEventListener(event, onDragStart, false)
      })
      unHighlightEvents.forEach(event => {
        dropArea.addEventListener(event, onDragEnd, false)
      })
      dropArea.addEventListener('drop', onDrop, false)
    }

    return () => {
      if (dropArea) {
        dragEvents.forEach(event => {
          dropArea.removeEventListener(event, preventDefaults, false)
        })
        highlightEvents.forEach(event => {
          dropArea.removeEventListener(event, onDragStart, false)
        })
        unHighlightEvents.forEach(event => {
          dropArea.removeEventListener(event, onDragEnd, false)
        })
        dropArea.removeEventListener('drop', onDrop, false)
      }
    }
  }, [dropAreaRef, preventDefaults, onDragStart, onDragEnd, onDrop])
}
