import React, { type FunctionComponent } from 'react'
import { Text } from '../../text/Text'

export const DateRangePickerLabelText: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <Text weight="bold" size="medium" css={{ marginBottom: 8 }}>
    {children}
  </Text>
)
