import { type TreeNodeInArray } from 'react-simple-tree-menu'

/**
 * Populates an object that maps node key => all the labels within that branch, e.g.
 * The following tree
 *  └── Vegetables/
 *       ├── Tubers/
 *       │   └── Potatoes
 *       └── Lettuce
 *
 * Would be converted to
 * 1 => 'vegetables~tubers~potatoes~lettuce'
 * 2 => 'tubers~potatoes'
 * 3 => 'potatoes
 * 4 => 'lettuce'
 *
 * Which allows you to pick all the related nodes when searching.
 *
 * @param item
 * @param store
 * @returns
 */
export const recursiveFetchLabelsTable = (
  item: TreeNodeInArray,
  store: Record<string, string>
): string => {
  const valueFragments = [item.label.toLowerCase()]

  if (item.nodes?.length) {
    valueFragments.push(...item.nodes.map(item => recursiveFetchLabelsTable(item, store)))
  }

  // Use a 'rare' delimiter, that's unlikely to be used by the retailers themselves
  const flatValues = valueFragments.join('~')

  store[item.key] = flatValues

  return flatValues
}
