import React, { type FunctionComponent, useMemo, useState, useRef, useCallback } from 'react'
import { useFilestack } from '../../hooks/useFilestack.hooks'
import { DropArea, FileInput } from './utils/fileUploader.styles'
import { type FileUploaderProps, type FileUploaderErrorTypes } from './utils/fileUploader.types'
import { useDragAndDrop } from './utils/fileUploader.hooks'

/**
 * File upload component with built-in support for drag and drop and uploading to Filestack.
 */
export const FileUploader: FunctionComponent<React.PropsWithChildren<FileUploaderProps>> = ({
  accept,
  uploadToFilestack = false,
  filestackApiKey = 'noupload',
  validateFile,
  onUpload,
  DropAreaComponent,
  droppedAreaComponentMessages,
  DroppedFileComponent,
  ErrorComponent,
  errorComponentMessages,
  className,
  container,
  region,
  path,
}) => {
  const [file, setFile] = useState<File | null>(null)
  const [errorType, setErrorType] = useState<FileUploaderErrorTypes | null>(null)
  const [highlightDropArea, setHighlightDropArea] = useState<boolean>(false)

  const { url, uploading, uploadProgress, uploadError, uploadFile } = useFilestack({
    value: null,
    filestackApiKey,
    onFileUpload: (uploadUrl: string, file: File, key) => onUpload(file, uploadUrl, key),
  })

  const fileInputRef = useRef<HTMLInputElement>(null)
  const dropAreaRef = useRef<HTMLDivElement>(null)

  const handleFile = useCallback(
    (file: File) => {
      setFile(file)
      setErrorType(null)

      if (validateFile) {
        const validationError = validateFile(file)

        if (validationError) {
          setErrorType(validationError)
          return
        }
      }

      if (uploadToFilestack) {
        uploadFile(file, container, region, path)
      } else {
        onUpload(file)
      }
    },
    [
      setFile,
      setErrorType,
      validateFile,
      uploadToFilestack,
      uploadFile,
      onUpload,
      container,
      region,
      path,
    ]
  )

  /** Called when a file is dropped onto the drop area. */
  const handleFileDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault()

      const file = e.dataTransfer?.files?.item(0)
      if (file) {
        handleFile(file)
      }
    },
    [handleFile]
  )

  /** Called when a file is selected from the system filepicker dialog. */
  const handleFileInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()

      const file = e.target.files?.[0] || e.target.files?.item(0)
      if (file) {
        handleFile(file)
      }
    },
    [handleFile]
  )

  const openFilePicker = useCallback(() => {
    const fileInput = fileInputRef.current

    if (fileInput) {
      fileInput.value = ''
      fileInput.click()
    }
  }, [fileInputRef])

  /** Set up drag and drop functionality for the drop area. */
  useDragAndDrop({
    dropAreaRef,
    onDragStart: () => setHighlightDropArea(true),
    onDragEnd: () => setHighlightDropArea(false),
    onDrop: handleFileDrop,
  })

  const content = useMemo(() => {
    if (file) {
      if (errorType) {
        return (
          <ErrorComponent
            fileName={file.name}
            errorType={errorType}
            messages={errorComponentMessages}
          />
        )
      } else if (uploadError) {
        return (
          <ErrorComponent
            fileName={file.name}
            errorType="uploadError"
            messages={errorComponentMessages}
          />
        )
      }

      return (
        <DroppedFileComponent
          fileName={file.name}
          url={url}
          uploading={uploading}
          uploadProgress={uploadProgress}
        />
      )
    }

    return <DropAreaComponent messages={droppedAreaComponentMessages} />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType, file, uploading, uploadProgress, uploadError, url])

  return (
    <DropArea
      ref={dropAreaRef}
      highlight={highlightDropArea}
      onClick={openFilePicker}
      className={className}
    >
      <FileInput
        data-testid="file-input"
        type="file"
        accept={accept}
        ref={fileInputRef}
        onChange={handleFileInput}
      />
      {content}
    </DropArea>
  )
}
