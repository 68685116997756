import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { INSTACART_SANS_FONT_FAMILY } from '@instacart/ids-core'
import { colors, fontSize, fontWeight } from '../../foundation'

const StyledSelect = styled.select({
  border: 'none',
  height: '30px',
  overflow: 'visible',
  '&:focus': {
    outline: 'none',
  },
  background: 'transparent',
})

const StyledOption = styled.option({
  fontSize: fontSize.X15,
  padding: '3px 8px',
  justifyContent: 'center',
  marginRight: '20px',
  display: 'inline-flex',
  cursor: 'pointer',
  borderRadius: '14px',
  backgroundColor: colors.GRAYSCALE.X20,
  fontWeight: fontWeight.SEMIBOLD,
  color: colors.GRAYSCALE.X70,
  fontFamily: `'${INSTACART_SANS_FONT_FAMILY}'`,
  ':last-of-type': {
    marginRight: 0,
  },
  '&:focus': {
    outline: 'none',
  },
  '&:checked': {
    boxShadow: `0 0 10px 100px ${colors.GRAYSCALE.X20} inset`,
    outline: '2px solid black',
  },
})

type PillItem = {
  label: string
  value: string
}

export type PillSelectorProps = {
  items: PillItem[]
  name?: string
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  defaultValue?: string
  testId?: string
  value?: string
}

export const PillSelector: FunctionComponent<React.PropsWithChildren<PillSelectorProps>> = ({
  items,
  name,
  onChange = () => null,
  defaultValue,
  disabled = false,
  testId,
  value,
}) => {
  const list = items.map((item, index) => (
    <StyledOption key={index} value={item.value} label={item.label}>
      {item.label}
    </StyledOption>
  ))
  return (
    <StyledSelect
      data-testid={testId}
      disabled={disabled}
      defaultValue={defaultValue ? [defaultValue] : undefined}
      value={value ? [value] : undefined}
      onChange={e => onChange(e)}
      name={`${name}-pill-select`}
      multiple
    >
      {list}
    </StyledSelect>
  )
}
