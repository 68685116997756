import React, { type FunctionComponent, useMemo, type HTMLAttributes } from 'react'
import range from 'lodash/range'
import moment from 'moment'
import { NewSelect } from '../new-select/NewSelect'
import { type SimpleOption } from '../new-select/utils/types'

export interface TimeOfDay {
  hours: number
  minutes: number
}

export type MinuteIncrements = 15 | 30 | 60

export interface Internationalization {
  day: string
}

export interface TimeOfDaySelectProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'defaultValue'> {
  value?: TimeOfDay
  onChange: (newTimeOfDay?: TimeOfDay) => void
  rangeStartHour?: number
  rangeStartMinute?: number
  rangeEndHour?: number
  rangeEndMinute?: number
  minuteIncrements?: MinuteIncrements
  isModernTheme?: true
  i18n?: Internationalization
  isDisabled?: boolean
  inputId?: string
}

// omit 'value' since it has a string datatype from SimpleSelectProps
// we want to use the TimeOfDay datatype from TimeOfDaySelectProps instead
export const TimeOfDaySelect: FunctionComponent<
  React.PropsWithChildren<TimeOfDaySelectProps & Partial<Omit<SimpleOption<string>, 'value'>>>
> = ({
  value,
  rangeStartHour = 0,
  rangeStartMinute = 0,
  rangeEndHour = 24,
  rangeEndMinute = 0,
  minuteIncrements = 30,
  onChange,
  i18n = { day: 'day' },
  ...rest
}) => {
  const options = useMemo(() => {
    const possibleTimeOfDays: TimeOfDay[] = range(rangeStartHour, rangeEndHour + 1)
      .map(hour => {
        let minutes: number[] = []
        switch (minuteIncrements) {
          case 15:
            minutes = [0, 15, 30, 45]
            break
          case 30:
            minutes = [0, 30]
            break
          case 60:
            minutes = [0]
            break
        }
        return minutes.map(minutes => ({ hours: hour, minutes: minutes } as TimeOfDay))
      })
      .flat()
      // filter out the outer bounds of the TimeOfDay list
      // eg: 00:00 gets filtered out when rangeStartHour = 1
      // eg: 24:00 gets filtered out when rangeEndHour = 23 && rangeEndMinute == 30
      .filter(
        timeOfDay => timeOfDay.hours > rangeStartHour || timeOfDay.minutes >= rangeStartMinute
      )
      .filter(timeOfDay => timeOfDay.hours < rangeEndHour || timeOfDay.minutes <= rangeEndMinute)

    const options: SimpleOption<string>[] = possibleTimeOfDays.map(tod => ({
      label: timeOfDayToOptionLabel(tod, i18n),
      value: timeOfDayToOptionValue(tod),
    }))

    return options
  }, [minuteIncrements, rangeStartHour, rangeStartMinute, rangeEndHour, rangeEndMinute, i18n])

  return (
    <NewSelect
      {...rest}
      options={options}
      value={value ? timeOfDayToOptionValue(value) : undefined}
      onChange={(newValue: string | undefined) => {
        onChange(optionValueToTimeOfDay(newValue))
      }}
    />
  )
}

/**
 * Takes a time of day and formats it to a 24-hour clock,
 * with day rollover added to the hour digit.
 *
 * Eg:
 * 0:00
 * 7:00
 * 16:55
 * 25:20
 *
 * @param timeOfDay the time of day to format
 * @returns a 24+hour formatted string version of time of day
 */
const timeOfDayToOptionValue = (timeOfDay: TimeOfDay) => {
  const hour = timeOfDay.hours?.toString() || '00' // defaults to '00' for the use case when going from 24h to Open
  let minute = timeOfDay.minutes?.toString() || '00' // defaults to '00' for the use case when going from 24h to Open
  minute = minute.padStart(2, '0')
  return `${hour}:${minute}`
}

/**
 * Takes a time of day and formats it to a 12-hour clock,
 * including +X for extra rollover days.
 *
 * Eg:
 * 12:00am
 * 8:00am
 * 4:55pm
 * 1:20am (+1 day)
 *
 * @param timeOfDay the time of day to format
 * @returns a 12hour formatted string version of time of day
 */
const timeOfDayToOptionLabel = (timeOfDay: TimeOfDay, i18n: Internationalization) => {
  const normalizedHour = timeOfDay.hours % 24
  const plusDay = Math.floor(timeOfDay.hours / 24)
  const todMoment = moment({ hour: normalizedHour, minute: timeOfDay.minutes })
  let formatted = todMoment.format('LT')

  if (plusDay > 0) {
    formatted += ` (+${plusDay} ${i18n.day})`
  }

  return formatted
}

/**
 * Takes a 24hour formatted string version of time of day (which was
 * created with `timeOfDayToOptionValue`) and converts it back into
 * a TimeOfDay instance.
 *
 * @param timeOfDay a 24hour formatted string version of time of day
 * @returns a converted TimeOfDay instance
 */
const optionValueToTimeOfDay = (timeOfDay?: string) => {
  if (!timeOfDay) return undefined

  const split = timeOfDay.split(':')
  if (split.length != 2) {
    throw new Error('Badly formatted time of day: ' + timeOfDay)
  }

  return { hours: parseInt(split[0]), minutes: parseInt(split[1]) } as TimeOfDay
}
