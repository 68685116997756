import React, { type FunctionComponent } from 'react'
import { css } from '@emotion/react'
import { spacing } from '../../foundation/spacing'
import { fontSize, defaultLineHeight } from '../../foundation/fontSize'

const sectionContentStyles = css({
  width: '100%',
  borderRadius: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  marginTop: spacing.X12,
  marginBottom: spacing.X12,
  paddingLeft: spacing.X20,
  paddingRight: spacing.X20,
  fontSize: fontSize.X15,
  lineHeight: defaultLineHeight,
})

/**
 * @deprecated use Card instead from @retailer-platform/shared-components
 */
export const SectionContent: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
  ...props
}) => (
  <div css={sectionContentStyles} {...props}>
    {children}
  </div>
)
