import styled from '@emotion/styled'
import { borderRadius, colors, spacing } from '../../../foundation'

export const DropArea = styled.div(
  {
    alignItems: 'center',
    borderStyle: 'dashed',
    borderWidth: 2,
    borderRadius: borderRadius.X8,
    borderColor: colors.GRAYSCALE.X30,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: spacing.X24,
  },

  (props: { highlight: boolean }) => {
    if (props.highlight) {
      return { borderColor: colors.GRAYSCALE.X50, backgroundColor: colors.GRAYSCALE.X10 }
    }
  }
)

export const FileInput = styled.input({
  display: 'none',
})
