import React, { type ReactElement } from 'react'
import { LoadingLockupTextBase } from '@instacart/ids-core'
import { gridLoadingRowStyles } from '../utils/grid.styles'
import { type TestID } from '../../../utils/testing/types'

interface Props extends TestID {}

export const GridLoadingRow = ({ 'data-testid': testId }: Props): ReactElement => (
  <div css={gridLoadingRowStyles} data-testid={testId}>
    <LoadingLockupTextBase css={{ height: '100%' }} />
  </div>
)
