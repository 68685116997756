import React, { Fragment } from 'react'
import { UploadedFileName, UploadProgressBar } from '../utils/csvUploader.styles'
import { type FileUploader } from '../../../common/file-uploader/FileUploader'

type CsvUploaderDroppedFileProps = React.ComponentProps<typeof FileUploader>['DroppedFileComponent']

export const CsvUploaderDroppedFile: CsvUploaderDroppedFileProps = ({
  fileName,
  uploading,
  uploadProgress,
}) => (
  <Fragment>
    <UploadedFileName size="medium">{fileName}</UploadedFileName>
    {uploading && <UploadProgressBar current={uploadProgress} total={100} loadingBarWidth="100%" />}
  </Fragment>
)
