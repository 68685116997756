import styled from '@emotion/styled'
import { type Moment } from 'moment'
import React, { type FunctionComponent, useState } from 'react'
import 'react-dates/initialize'
import { SingleDatePicker as ReactDatePicker } from 'react-dates'

export type OpenDirection = 'up' | 'down'

export interface Props {
  id: string
  value?: Moment
  onChange: (newValue: Moment | null) => void
  width?: string
}

const bigClassSelector =
  '.SingleDatePickerInput__withBorder .DateInput_input, .SingleDatePickerInput__withBorder_2 .DateInput_input'

const Wrapper = styled.div<{ width?: string }>(
  {
    [bigClassSelector]: {
      height: '32px',
      borderColor: '#C7C8CD',
      fontSize: '13px',
      borderRadius: '8px',
    },
  },
  ({ width }) => ({
    [bigClassSelector]: {
      width: width,
    },
  })
)

export const SingleDatePicker: FunctionComponent<
  React.PropsWithChildren<
    Props &
      Omit<
        React.ComponentProps<ReactDatePicker>,
        'date' | 'focused' | 'onDateChange' | 'onFocusChange'
      >
  >
> = ({
  id,
  value,
  onChange,
  width,
  // @ts-expect-error ignore this prop to prevent it from going into ReactDatePicker, prevents a runtime error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  name,
  // @ts-expect-error ignore this prop to prevent it from going into ReactDatePicker, prevents a runtime error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onBlur,
  ...rest
}) => {
  const [focused, setFocused] = useState(false)

  return (
    <Wrapper width={width}>
      <ReactDatePicker
        id={id}
        numberOfMonths={2}
        date={value || null}
        onDateChange={onChange}
        focused={focused}
        onFocusChange={change => {
          setFocused(change.focused || false)
        }}
        {...rest}
      />
    </Wrapper>
  )
}
