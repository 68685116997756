import React, { useCallback, useState, useMemo, useRef, type CSSProperties } from 'react'
import merge from 'deepmerge'
import Highcharts, {
  type Chart as HighchartsChartType,
  type TooltipFormatterContextObject,
} from 'highcharts'
import highchartsExporting from 'highcharts/modules/exporting'
import ExportData from 'highcharts/modules/export-data'
import HighchartsReact from 'highcharts-react-official'
import { colors } from '../../foundation'
import { Tooltip } from './Tooltip'

ExportData(Highcharts)
highchartsExporting(Highcharts)

Highcharts.setOptions({
  colors: [
    '#7cb5ec',
    '#434348',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
    '#e4d354',
    '#2b908f',
    '#f45b5b',
    '#91e8e1',
  ],
  chart: {
    style: {
      fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
      fontSize: '16px',
    },
  },
  title: {
    style: {
      fontWeight: 'normal',
      fontSize: '18px',
    },
  },
  plotOptions: {
    area: {
      lineWidth: 2,
    },
    column: {
      borderRadius: 0,
    },
    pie: {
      borderRadius: 0,
      dataLabels: {
        connectorShape: 'fixedOffset',
      },
    },
    line: {
      lineWidth: 2,
    },
    spline: {
      lineWidth: 2,
    },
  },
  tooltip: {
    borderWidth: 1,
  },
  legend: {
    itemStyle: {
      fontWeight: 'bold',
    },
    itemHiddenStyle: {
      color: '#cccccc',
      textDecoration: 'none',
    },
  },
  xAxis: {
    labels: {
      distance: 8,
      style: {
        color: '#666666',
        fontSize: '11px',
      },
    },
    lineColor: '#ccd6eb',
  },
  yAxis: {
    labels: {
      distance: 8,
      style: {
        color: '#666666',
        fontSize: '11px',
      },
    },
  },
  colorAxis: {
    labels: {
      distance: 8,
      style: {
        color: '#666666',
        fontSize: '11px',
      },
    },
    maxColor: '#003399',
    minColor: '#e6ebf5',
  },
  scrollbar: {
    barBorderRadius: 0,
    barBorderWidth: 1,
    buttonsEnabled: true,
    height: 14,
    margin: 0,
    rifleColor: '#333',
    trackBackgroundColor: '#f2f2f2',
    trackBorderRadius: 0,
  },
})

export type GraphType = 'column' | 'bar' | 'spline' | 'line'

export interface GenericChartProps {
  data?: Array<{ x: string | number; y: string | number }>
  title?: string
  seriesName: string
  showExportMenu?: boolean
  optionProps?: Highcharts.Options
  color?: string
  graphType?: GraphType
  formatter?: (value: number) => string
  CustomTooltip?: (formatterContext: TooltipFormatterContextObject) => JSX.Element
  showInLegend?: boolean
  containerStyle?: CSSProperties
  downloadCSVSideEffect?: () => void
  showYAxisBothSides?: boolean
  truncateYAxis?: boolean
  primaryDataFillColor?: string | null
  // Options here will override the default options of the component instead of merging with them
  overrideOptionProps?: Highcharts.Options
}

export const GenericChart = ({
  data,
  title,
  showExportMenu = true,
  optionProps = {},
  seriesName,
  color = colors.PRIMARY.REGULAR,
  CustomTooltip,
  formatter,
  graphType = 'column',
  showInLegend = false,
  containerStyle,
  downloadCSVSideEffect,
  truncateYAxis = false,
  showYAxisBothSides = false,
  overrideOptionProps = {},
  primaryDataFillColor,
}: GenericChartProps) => {
  const [chart, setChart] = useState<HighchartsChartType | null>(null)
  const chartRef = useRef<HighchartsReact.RefObject>(null)
  const callback = useCallback((chart: HighchartsChartType) => {
    setChart(chart)
  }, [])

  const spacingTop = !title ? 60 : 0

  // workaround for highcharts blocking on blur event https://github.com/highcharts/highcharts/issues/4606#issuecomment-149152653
  Highcharts.wrap(Highcharts.Pointer.prototype, 'onContainerMouseDown', function () {
    return
  })

  const options: Highcharts.Options = useMemo(
    () => ({
      exporting: showExportMenu
        ? {
            enabled: true,
            menuItemDefinitions: {
              downloadCSV: {
                onclick: () => {
                  downloadCSVSideEffect && downloadCSVSideEffect()
                  chartRef && chartRef.current && chartRef.current.chart.downloadCSV()
                },
                text: 'Download CSV',
              },
            },
            buttons: {
              contextButton: {
                menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadCSV'],
                y: 0 - spacingTop,
              },
            },
          }
        : {
            enabled: false,
          },
      title: {
        text: title,
        align: 'left',
      },
      xAxis: {
        categories:
          data && data[0] && typeof data[0].x === 'string'
            ? data.map(dataItem => dataItem.x as string)
            : undefined,
        crosshair: true,
      },
      yAxis: [
        {
          labels: {
            formatter: truncateYAxis
              ? undefined
              : function (this) {
                  if (formatter) {
                    return formatter(+this.value)
                  }
                  return `${this.value}`
                },
          },
          title: {
            enabled: false,
            text: undefined,
          },
        },
        showYAxisBothSides
          ? {
              linkedTo: 0,
              opposite: true,
              title: {
                enabled: false,
                text: undefined,
              },
            }
          : { title: { enabled: false } },
      ],
      chart: {
        borderRadius: 12,
        spacingTop: spacingTop,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            formatter: function (this) {
              if (formatter && this.y) {
                return formatter(this.y)
              }

              return `${this.y}`
            },
          },
        },
      },
      tooltip: {
        shared: true,
      },
      credits: {
        enabled: false,
      },
      series: data
        ? [
            {
              name: seriesName,
              showInLegend,
              type: primaryDataFillColor ? 'area' : graphType,
              color: color,
              fillColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, primaryDataFillColor || ''],
                  [1, 'rgba(247, 247, 247, 0.28)'],
                ],
              },
              data: data.map(dataItem => {
                if (typeof dataItem.x == 'number')
                  return { x: dataItem.x as number, y: dataItem.y as number }
                else return dataItem.y
              }),
            },
          ]
        : undefined,
    }),
    [
      title,
      data,
      seriesName,
      truncateYAxis,
      graphType,
      showExportMenu,
      color,
      formatter,
      showInLegend,
      spacingTop,
      showYAxisBothSides,
      primaryDataFillColor,
      downloadCSVSideEffect,
    ]
  )
  const completeOptions = { ...merge(options, optionProps), ...overrideOptionProps }

  return (
    <>
      <HighchartsReact
        ref={chartRef}
        containerProps={{ style: containerStyle ? containerStyle : { height: '100%' } }}
        highcharts={Highcharts}
        options={completeOptions}
        callback={callback}
      />
      {chart && CustomTooltip && <Tooltip chart={chart}>{CustomTooltip}</Tooltip>}
    </>
  )
}
