import React, { type FunctionComponent } from 'react'
import { type TreeMenuItem } from 'react-simple-tree-menu'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { LegalIcon, CategoryIcon, ChevronRightIcon, useTheme } from '@instacart/ids-core'
import { colors, borderRadius, fontWeight } from '../../foundation'

const LEVEL_PADDING = 16
const DEFAULT_PADDING = 4

interface Props extends TreeMenuItem {
  itemKey: string
  highlightActive?: boolean
  hideIcons?: boolean
  toggleOnActivation?: boolean
}

const TreeItemWrapper = styled.div<{
  level: number
  hoverColor: string
  active: boolean
  displayArrowOnLeft: boolean
}>(
  {
    maxWidth: '100%',
    border: 'none',
    height: 40,
    listStyleType: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: borderRadius.X4,
    fontSize: 15,
  },
  ({ level, hoverColor, active, displayArrowOnLeft }) => ({
    fontWeight: active ? fontWeight.BOLD : fontWeight.REGULAR,
    paddingLeft: level * LEVEL_PADDING + DEFAULT_PADDING,
    paddingRight: DEFAULT_PADDING,
    flexDirection: displayArrowOnLeft ? 'row-reverse' : 'inherit',
    background: active ? colors.GRAYSCALE.X20 : 'transparent',
    '&:hover': {
      backgroundColor: hoverColor,
    },
  })
)

const LabelSection = styled.li({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  height: '100%',
  wordBreak: 'break-word',
  maxWidth: 'calc(100% - 40px)',
})

const iconProps = {
  size: 20,
  css: css({ paddingRight: 10 }),
  color: 'systemGrayscale50',
} as const

const chevronStyles = (isOpen: boolean) => css({ transform: isOpen ? 'rotate(90deg)' : 'none' })

const ChevronWrapper = styled.div<{ position: 'left' | 'right' }>(
  {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
  },
  ({ position }) => ({
    [position === 'right' ? 'paddingRight' : 'paddingLeft']: 10,
  })
)

export const TreeItem: FunctionComponent<React.PropsWithChildren<Props>> = ({
  level,
  hasNodes,
  isOpen,
  label,
  toggleNode,
  onClick,
  itemKey,
  active,
  className,
  highlightActive,
  displayArrowOnLeft,
  hideIcons,
  children,
  toggleOnActivation,
}) => {
  const { colors } = useTheme()

  return (
    <TreeItemWrapper
      level={level}
      hoverColor={colors.systemGrayscale10}
      active={(highlightActive && active) as boolean}
      className={className}
      displayArrowOnLeft={displayArrowOnLeft}
    >
      <LabelSection
        data-testid={`tree-item-${itemKey}`}
        onClick={(...args) => {
          onClick(...args)

          if (toggleOnActivation) {
            toggleNode?.()
          }
        }}
      >
        {!hideIcons && (
          <div className="tree-item-icon">
            {hasNodes ? <LegalIcon {...iconProps} /> : <CategoryIcon {...iconProps} />}
          </div>
        )}
        {children || label}
      </LabelSection>

      {hasNodes && (
        <ChevronWrapper
          position={displayArrowOnLeft ? 'left' : 'right'}
          onClick={toggleNode}
          className="tree-item-toggle"
        >
          <ChevronRightIcon
            color="systemGrayscale30"
            size={14}
            css={chevronStyles(isOpen)}
            data-testid={`tree-item-toggle-icon-${itemKey}`}
          />
        </ChevronWrapper>
      )}
    </TreeItemWrapper>
  )
}
