import React, { type FunctionComponent, Fragment } from 'react'
import styled from '@emotion/styled'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import { colors, fontSize, fontWeight } from '../../foundation'
import { LoadingDots } from '../loading-dots/LoadingDots'
import { Tooltip } from '../tooltip/Tooltip'
import { MetricCardChange } from './components/MetricCardChange'

export type MetricCardValue = number | undefined | null

export interface MetricCardProps {
  title?: string
  valueFormatFn: (value: number) => string
  value: MetricCardValue
  valueLabel?: string
  secondaryValueLabel?: string
  defaultValue?: string
  comparisonValue?: MetricCardValue
  secondaryValue?: MetricCardValue
  secondaryComparisonValue?: MetricCardValue
  desiredDirection?: 'positive' | 'negative'
  changeSuffix?: string
  secondaryChangeSuffix?: string
  tooltip?: string
  'data-testid'?: string
  isLoading?: boolean
  active?: boolean
  withWhiteBackground?: boolean
  onClick?: () => void
  className?: string
}

const MetricContainer = styled.div(
  ({
    active,
    onClick,
    withWhiteBackground,
  }: Pick<MetricCardProps, 'active' | 'onClick' | 'withWhiteBackground'>) => ({
    height: 92,
    minWidth: 170,
    fontSize: fontSize.X15,
    boxShadow: withWhiteBackground
      ? active
        ? '0px 2px 8px rgba(0, 0, 0, 0.16)'
        : 'none'
      : '0px 2px 8px rgba(0, 0, 0, 0.16)',
    width: 216,
    border: withWhiteBackground
      ? `1px solid ${colors.GRAYSCALE.X20}`
      : active
      ? `1px solid ${colors.GRAYSCALE.X50}`
      : 'default',
    borderRadius: '12px',
    cursor: onClick ? 'pointer' : 'default',
    backgroundColor: withWhiteBackground
      ? active
        ? colors.GRAYSCALE.X0
        : colors.GRAYSCALE.X10
      : colors.GRAYSCALE.X0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  })
)

const ValueContainer = styled.div(({ hasComparison }: { hasComparison: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: hasComparison ? '50px' : '30px',
}))

const Value = styled.div({
  fontSize: fontSize.X18,
  lineHeight: '26px',
  fontWeight: fontWeight.SEMIBOLD,
  marginBottom: '4px',
})

const GrowthPercentageGroupContainer = styled.div({
  display: 'flex',
})

const TitleContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: fontSize.X12,
  lineHeight: '18px',
  justifyContent: 'center',
}))

export const MetricCard: FunctionComponent<React.PropsWithChildren<MetricCardProps>> = ({
  title,
  valueFormatFn,
  value,
  defaultValue = '--',
  comparisonValue,
  secondaryValue,
  secondaryComparisonValue,
  desiredDirection,
  changeSuffix,
  secondaryChangeSuffix,
  tooltip,
  'data-testid': testId,
  isLoading,
  active,
  withWhiteBackground,
  onClick,
  ...rest
}) => {
  const displayValue = isNil(value) ? defaultValue : valueFormatFn(value)

  return (
    <MetricContainer
      active={active}
      data-testid={testId}
      onClick={onClick}
      withWhiteBackground={withWhiteBackground}
      {...rest}
    >
      <ValueContainer hasComparison={comparisonValue !== undefined}>
        {isLoading ? (
          <div css={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <LoadingDots testId="metric-card-loading" height={15} />
          </div>
        ) : (
          <>
            <Value>{displayValue}</Value>

            <GrowthPercentageGroupContainer>
              <MetricCardChange
                value={value}
                comparisonValue={comparisonValue}
                changeSuffix={changeSuffix}
                desiredDirection={desiredDirection}
              />
              {isNumber(secondaryValue) && isNumber(secondaryComparisonValue) && (
                <Fragment>
                  /
                  <MetricCardChange
                    value={secondaryValue}
                    comparisonValue={secondaryComparisonValue}
                    changeSuffix={secondaryChangeSuffix || changeSuffix}
                    desiredDirection={desiredDirection}
                  />
                </Fragment>
              )}
            </GrowthPercentageGroupContainer>
          </>
        )}
      </ValueContainer>

      <TitleContainer>
        {tooltip ? (
          <Tooltip
            placement="bottom"
            target={
              <div css={{ borderBottom: `1px dashed ${colors.GRAYSCALE.X50}`, cursor: 'help' }}>
                {title}
              </div>
            }
          >
            {tooltip}
          </Tooltip>
        ) : (
          <div>{title}</div>
        )}
      </TitleContainer>
    </MetricContainer>
  )
}

export const MetricCardsContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  justifyContent: 'flex-start',
})
