import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { UploadIcon } from '@instacart/ids-core'
import { type FileUploader } from '../../../common/file-uploader/FileUploader'
import { spacing } from '../../../foundation'
import { Button } from '../../../common/button/Button'
import { Text } from '../../../common/text/Text'

type CsvUploaderDropAreaProps = React.ComponentProps<typeof FileUploader>['DropAreaComponent']

const StyledUploadIcon = styled(UploadIcon)({
  marginBottom: spacing.X8,
})

export const CsvUploaderDropArea: CsvUploaderDropAreaProps = ({ messages }) => (
  <Fragment>
    <StyledUploadIcon size={20} />
    <Text size="large" weight="semibold">
      {messages?.uploadText}
    </Text>

    <Text css={{ marginTop: spacing.X20, marginBottom: spacing.X20 }}>{messages.or}</Text>

    <Button>{messages.uploadButton}</Button>
  </Fragment>
)
