import styled from '@emotion/styled'
import { fontSize } from '../../foundation/fontSize'
import { fontWeight } from '../../foundation/fontWeight'
import { spacing } from '../../foundation/spacing'

export const Label = styled.label({
  display: 'block',
  fontSize: fontSize.X12,
  fontWeight: fontWeight.SEMIBOLD,
  marginBottom: spacing.X4,
})
