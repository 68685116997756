/* eslint-disable @typescript-eslint/no-explicit-any */
import type React from 'react'
import { useDrag, useDrop, type DragLayerMonitor, type XYCoord } from 'react-dnd'
import { type Identifier } from 'dnd-core'

interface Props<DragItemDataType> {
  /**
   * Ref for the container element of the item being dropped.
   */
  dropRef: React.MutableRefObject<any>
  /**
   * Optional ref for when the drag source should
   * be different than the drop target element
   */
  dragRef?: React.MutableRefObject<any>
  /**
   * A string to differentiate this type of draggable element from
   * others on a page.
   */
  type: string
  /**
   * Callback for when dragged item at originalIndex is moved to index.
   */
  onDrag: (originalIndex: number, index: number, dragItemData?: DragItemDataType) => void
  /**
   * Callback for when dragged item is dropped
   */
  onDrop?: (dropParams: { didDrop: boolean; dropIndex: number }) => void
  /**
   * Index of the current item in this list of drag and drop items.
   */
  index: number
  /**
   * Only perform the move when the mouse has crossed this portion of the item height (default: 0.5).
   */
  threshold?: number
  /**
   * Optional data to be passed to onDrag callback about the item being dragged.
   */
  dragItemData?: DragItemDataType
}

interface DragItem<DragItemDataType> {
  index: number
  dragItemData?: DragItemDataType
}

/**
 * Hook for incorporating drag & drop reordering behavior into a component.
 */
export const useDragAndDropReorder = <DragItemDataType = {}>(props: Props<DragItemDataType>) => {
  const { type, onDrag, onDrop, index, dropRef, dragRef, threshold, dragItemData } = props

  const [{ handlerId }, drop] = useDrop<
    DragItem<DragItemDataType>,
    void,
    { handlerId: Identifier | null }
  >({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!dropRef.current) return

      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) * (threshold ?? 0.5)
      const clientOffset = monitor.getClientOffset()
      const hoverClientTop = (clientOffset as XYCoord).y - hoverBoundingRect.top
      const hoverClientBottom = (clientOffset as XYCoord).y - hoverBoundingRect.bottom

      // Only perform the move when the mouse has crossed half of the items height
      // when `threshold` is `0.5` (default).
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientTop < hoverMiddleY) return

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientBottom > hoverMiddleY) return

      onDrag(dragIndex, hoverIndex, item.dragItemData)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: type,
    item: (): DragItem<DragItemDataType> => ({ index, dragItemData }),
    collect: (monitor: DragLayerMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: ({ index }, monitor) => onDrop?.({ didDrop: monitor.didDrop(), dropIndex: index }),
  })

  preview(drop(dropRef))
  drag(dragRef ?? dropRef)

  return {
    /**
     * Whether or not the element is being dragged
     */
    isDragging,
    /**
     * To be added to the draggable element's data-handler-id attribute
     * to improve performance.
     */
    handlerId,
  }
}
