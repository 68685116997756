import React, { useMemo, useCallback } from 'react'
import { Select } from '../select/Select'
import { type MultiValueType, type SimpleOption } from '../select/utils/select.types'
import { type MultiSimpleSelectProps, type SimpleSelectValueType } from './simpleSelect.types'

const resolveOptions = <T extends SimpleOption<any>>(
  options: T[],
  values?: T['value'][]
): MultiValueType<T> => {
  if (!values) return []

  return options.filter(option => values.includes(option.value))
}

/**
 * Ideally this should be just a prop (isMulti), but somewhere along the line
 * TS loses the capability to infer the types based off the options. I think this might have
 * been related to configuration issues, as this only happens in dashboard, but not shared-components
 *
 * @deprecated Prefer using the NewSelect component from @retailer-platform/shared-components
 */
export const MultiSimpleSelect = <TValue extends SimpleSelectValueType = string>(
  props: MultiSimpleSelectProps<TValue>
) => {
  const { value, onChange, defaultValue, options, ...rest } = props

  const resolvedValue = useMemo(() => resolveOptions(options, value), [value, options])
  const resolvedDefaultValue = useMemo(
    () => resolveOptions(options, defaultValue),
    [defaultValue, options]
  )

  const handleChange = useCallback(
    (value: MultiValueType<SimpleOption<TValue>>) => onChange(value?.map(opt => opt.value)),
    [onChange]
  )

  return (
    <Select
      {...rest}
      onChange={handleChange}
      options={options}
      value={resolvedValue}
      defaultValue={resolvedDefaultValue}
      isMulti
    />
  )
}
