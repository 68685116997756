import React from 'react'
import { GenericChart, type GenericChartProps } from '../generic-chart/GenericChart'

type SeriesDataItem = {
  value: number | string
  comparisonValue: number | string
  comparisonCategory: string
  category: string
}

type SeriesData = {
  seriesData: SeriesDataItem[]
  name?: string
  comparisonSeriesName?: string
  color: string
  comparisonColor: string
}

interface Props {
  title?: string
  chartData: SeriesData
  labelValueType?: 'percent' | 'normal'
  formatter?: (v: number) => string
  optionProps?: GenericChartProps['optionProps']
}

export const HorizontalBreakdownBarChart = ({
  title,
  chartData,
  labelValueType = 'normal',
  formatter,
  optionProps,
  ...rest
}: Props & Omit<GenericChartProps, 'seriesName'>) => {
  const { seriesData } = chartData
  return (
    <div style={{ overflow: 'visible' }}>
      <GenericChart
        containerStyle={{ overflow: 'visible' }}
        optionProps={{
          xAxis: [
            {
              labels: {
                overflow: 'allow',
                style: {
                  width: 200,
                },
              },
              categories: seriesData.map(dataItem => dataItem.category),
            },
            {
              labels: {
                overflow: 'allow',
                style: {
                  width: 200,
                },
              },
              categories: seriesData.map(dataItem => dataItem.comparisonCategory),
              linkedTo: 0,
              opposite: true,
            },
          ],
          plotOptions: {
            series: {
              states: {
                inactive: {
                  opacity: 1,
                },
              },
              stacking: labelValueType,
              dataLabels: {
                formatter: function () {
                  if (formatter && this.y) return formatter(this.y)
                  if (labelValueType === 'percent') return `${this.percentage.toFixed(1)}%`
                  return this.y
                },
                enabled: true,
              },
            },
          },
          legend: { enabled: false },
          series: [
            {
              type: 'bar',
              name: chartData.comparisonSeriesName,
              data: seriesData.map(dataItem => dataItem.comparisonValue),
              color: chartData.comparisonColor,
              label: {
                enabled: false,
              },
            },
            {
              type: 'bar',
              name: chartData.name,
              data: seriesData.map(dataItem => dataItem.value),
              color: chartData.color,
            },
          ],
          ...optionProps,
        }}
        seriesName=""
        title={title}
        graphType="bar"
        {...rest}
      />
    </div>
  )
}
