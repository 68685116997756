import React, { Fragment } from 'react'
import { UploadIcon } from '@instacart/ids-core'
import { type FileUploader } from '../../../common/file-uploader/FileUploader'
import { UploadedFileName, ErrorMessage, ErrorSeparator } from '../utils/csvUploader.styles'
import { Button } from '../../../common/button/Button'
import { Text } from '../../../common/text/Text'

type CsvUploaderErrorProps = React.ComponentProps<typeof FileUploader>['ErrorComponent']

export const CsvUploaderError: CsvUploaderErrorProps = ({ fileName, errorType, messages }) => (
  <Fragment>
    <UploadIcon size={20} />
    <UploadedFileName size="medium">{fileName}</UploadedFileName>

    <ErrorSeparator />
    <ErrorMessage>
      <Text size="medium">{messages[errorType]}</Text>
    </ErrorMessage>

    <Button>{messages.retry}</Button>
  </Fragment>
)
