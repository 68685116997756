import React, { type FunctionComponent } from 'react'
import { css, type SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { spacing } from '../../../foundation/spacing'
import { fontSize } from '../../../foundation/fontSize'
import { fontWeight } from '../../../foundation/fontWeight'
import { colors } from '../../../foundation/colors'
import { borderRadius } from '../../../foundation/borderRadius'
import { Text } from '../../text/Text'

const sectionHeaderStyles = css({
  width: '100%',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: colors.GRAYSCALE.X20,
  borderBottomRadius: borderRadius.X4,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: spacing.X52,
  paddingRight: spacing.X8,
})

const sectionTitleStyles = css({
  paddingLeft: spacing.X20,
  paddingRight: spacing.X20,
  fontSize: fontSize.X18,
  fontWeight: fontWeight.SEMIBOLD,
})

const sectionSubtitleStyles = css({
  height: spacing.X68,
})

const VerticalWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  alignContent: 'start',
  gap: spacing.X4,
})

const SubtitleWrapper = styled.div({
  paddingLeft: spacing.X20,
})

interface Props {
  title: string
  subTitle?: string
  headerStyles?: SerializedStyles
}

export const SectionHeader: FunctionComponent<React.PropsWithChildren<Props>> = ({
  title,
  subTitle,
  children,
  headerStyles,
}) => {
  let titleComponent = <span css={sectionTitleStyles}>{title}</span>
  let headerStylesFinal = [headerStyles, sectionHeaderStyles]

  if (subTitle) {
    const subtitleText = <Text>{subTitle}</Text>

    titleComponent = (
      <VerticalWrapper>
        {titleComponent}
        <SubtitleWrapper>{subtitleText}</SubtitleWrapper>
      </VerticalWrapper>
    )

    headerStylesFinal = [...headerStylesFinal, sectionSubtitleStyles]
  }

  return (
    <div css={headerStylesFinal}>
      {titleComponent}
      {children}
    </div>
  )
}
