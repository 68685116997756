import React, { type FunctionComponent } from 'react'
import { css } from '@emotion/react'
import clamp from 'lodash/clamp'
import { Text } from '../text/Text'
import { colors } from '../../foundation/colors'
import { spacing } from '../../foundation/spacing'
import { fontWeight } from '../../foundation/fontWeight'
export interface LoadingBarProps {
  total: number
  current: number
  message?: string
  loadingBarWidth?: number | string
}

const containerStyles = css({
  backgroundColor: colors.HIGHLIGHT.LIGHT,
  height: 24,
  marginLeft: spacing.X8,
  paddingRight: spacing.X8,
  display: 'flex',
  alignItems: 'center',
})

const textStyles = css({
  fontWeight: fontWeight.SEMIBOLD,
  color: colors.HIGHLIGHT.REGULAR,
})

const loadingBar = css({
  height: 4,
  borderRadius: 2,
  position: 'relative',
})

const progressBar = css({
  height: '100%',
  backgroundColor: colors.HIGHLIGHT.REGULAR,
  position: 'absolute',
  transition: 'width 0.5s',
})

const loadingBarTrack = css({
  height: '100%',
  width: '100%',
  backgroundColor: colors.HIGHLIGHT.REGULAR,
  opacity: 0.1,
  position: 'absolute',
})

export const LoadingBar: FunctionComponent<React.PropsWithChildren<LoadingBarProps>> = ({
  total,
  current,
  message,
  loadingBarWidth = 150,
  ...rest
}) => {
  const progressPercentage = `${clamp((current / total) * 100, 0, 100)}%`

  return (
    <div data-testid="loading-bar-container" css={containerStyles} {...rest}>
      <Text styles={textStyles}>{message}</Text>
      <div data-testid="loading-bar" css={css({ width: loadingBarWidth }, loadingBar)}>
        <div css={loadingBarTrack} />
        <div
          data-testid="loading-bar-progress-bar"
          css={css({ width: progressPercentage }, progressBar)}
        ></div>
      </div>
    </div>
  )
}
