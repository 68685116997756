import React from 'react'
import omit from 'lodash/omit'

/**
 * Removes props on the invocation of the decorated component.
 * This is mostly used to remove props that are injected (for instance,
 * react-router's) so they're not passed further into the actual dom element,
 * which could trigger some warnings.
 *
 * @param Component
 * @param omitKeys the props to remove
 * @returns the resulting component
 */
export const withoutProps =
  <T extends {}>(
    Component: React.ComponentType<React.PropsWithChildren<T>>,
    omitKeys: string[]
  ): React.FunctionComponent<React.PropsWithChildren<T>> =>
  props => {
    const filteredProps = omit(props, omitKeys) as T

    return <Component {...filteredProps} />
  }
