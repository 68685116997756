import React, { forwardRef } from 'react'
import { InputBase, type InputBaseProps } from '@instacart/ids-core'
import styled from '@emotion/styled'
import _ from 'lodash'
import { colors, fontSize } from '../../foundation'

export type DecoratedInputProps = {
  decorationPosition?: 'left' | 'right'
  compact?: boolean
  decorationComponent: React.ComponentType<React.PropsWithChildren<{ className?: string }>>
} & InputBaseProps

const Container = styled.div({
  position: 'relative',
})

/**
 * A decorated input is an input that has a decoration on the left or right side.
 *
 * The decoration is often an [icon from IDS](https://ids.instacart.tools/web/?path=/docs/atoms-icons--docs), but it can be
 * any other component that receives the prop `className`. Note that this component will modify the styles of the given component to ensure
 * the decoration fits within the input.
 *
 * This component utilizes the `InputBase` [component from IDS](https://ids.instacart.tools/web/?path=/docs/core-core-inputbase--docs), so all props from that component are available.
 */
export const DecoratedInput = forwardRef<HTMLInputElement, DecoratedInputProps>(
  (
    {
      decorationComponent: DecorationComponent,
      decorationPosition = 'left',
      compact = false,
      ...props
    },
    ref
  ) => {
    const css = _.omitBy(
      {
        [decorationPosition === 'left' ? 'paddingLeft' : 'paddingRight']: 33,
        height: compact ? 28 : null,
        fontSize: compact ? fontSize.X12 : null,
      },
      _.isNil
    )
    return (
      <Container>
        <InputBase css={css} {...props} ref={ref} />
        <DecorationComponent
          css={{
            position: 'absolute',
            top: compact ? 6 : 9,
            width: compact ? 16 : 20,
            height: compact ? 16 : 20,
            [decorationPosition]: 8,
            fill: colors.GRAYSCALE.X50,
          }}
        />
      </Container>
    )
  }
)
