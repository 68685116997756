import React, { type FunctionComponent } from 'react'
import { Button } from '../../button/Button'
import { type DateRange } from '../DateRangePickerV2'

interface DateRangePickerButtonsProps {
  onCancel: () => void
  onApply: () => void
  isClearable?: boolean
  onClear?: () => void
  appliedDateRange: DateRange
}
export const DateRangePickerButtons: FunctionComponent<
  React.PropsWithChildren<DateRangePickerButtonsProps>
> = ({ onCancel, onApply, isClearable, onClear, appliedDateRange }) => (
  <div>
    {(appliedDateRange.start || appliedDateRange.end) && isClearable && (
      <Button
        onClick={onClear}
        css={{ width: 160 }}
        variant="tertiary"
        data-testid="date-range-picker-clear"
      >
        Clear
      </Button>
    )}

    <Button onClick={onCancel} variant="secondary" data-testid="date-range-picker-cancel">
      Cancel
    </Button>

    <Button
      css={{ marginRight: 0 }}
      variant="primary"
      onClick={onApply}
      data-testid="date-range-picker-apply"
    >
      Apply
    </Button>
  </div>
)
